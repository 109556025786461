import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'amaly-admin';
  showHead = true;
  showSider = true;
  id
  token
  loggedInCls: boolean = false
  ngOnInit(): void {
    const loggedInAdmin = JSON.parse(localStorage.getItem('admin'));
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    
    if (loggedInAdmin) {
      this.loggedInCls = true;
    } else {
      this.loggedInCls = false;
    }
    if (loggedInUser) {
      this.loggedInCls = true;
    } else {
      this.loggedInCls = false;
    }
  }
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/' || event.url === '/login' || event.url === '/forgot-password' || event.url === '/register' || event.url == `/change-password/${this.id}` || event.url.indexOf('update-password') > -1) {
          this.showHead = false;
          this.showSider = false
        } else if (event.url === '/bu-dashboard') {
          this.showHead = true;
          this.showSider = true;
        } else {
          this.showHead = true;
          this.showSider = true;
        }
      }
    });
  }

}
