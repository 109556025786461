<div class="right_col screen-height v_scrnss edit-page" role="main">
   <div>
      <div class="col-sm-12 mb-4 ">
         <h1 class="text-left font-weight-bold text-uppercase text-dark">Revenue History</h1>
      </div>
      <div class=" amenity-size">
         <div class="container-fluid ">
            <div class="row justify-content-center dash_centr_bx dash_box_wrapp">
               <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">

                  <div class="small-box bg-info dash_box dash_bx1">
                     <div class="inner">
                        <h3>{{revenueReport.totalSubscriptions}}</h3>
                        <p>Total SubscriptionPlan</p>
                        <span class="icon"> <i class="fa fa-user-plus"></i></span>
                     </div>
                  </div>
               </div>
               <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                  <div class="small-box bg-success dash_box dash_bx2">
                     <div class="inner">
                        <h3>$&nbsp;{{showRevenue}}</h3>
                        <p>Total Revenue Generated</p>
                        <span class="icon"><i class='fa fa-money'></i></span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="revv_tab_wrap">
               <form [formGroup]="revenueReportForm">
                  <div class="rev_tabb">
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="month" (change)="filterAllPlans($event.target.value)">
                           <option *ngFor="let month of monthName; let id =index" value={{id+1}}>{{month}}</option>
                        </select>
                     </div>
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="year" (change)="filterAllPlans($event.target.value)">
                           <option *ngFor="let year of years;let id = index" value={{year}}>{{year}}</option>
                        </select>
                     </div>
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="plans" (change)="filterPlans($event.target.value)">
                           <option *ngFor="let plan of dropdownPlanIds; let id =index" value={{plan}}>{{plan}}</option>
                        </select>
                     </div>
                  </div>
               </form>
               <div class="subs_table_wrap subs_table_wdth">
                  <div class="table-responsive">
                     <table class="table table-bordered subs_table">
                        <thead>
                           <tr>
                              <th class="th-heading">Plan Name</th>
                              <th class="th-heading">SubscriptionPlan Name</th>
                              <th class="th-heading" *ngIf="this.revenueReportForm.controls['plans'].value ==='BoostPlans'">Boosts </th>
                              <th class="th-heading" *ngIf="this.revenueReportForm.controls['plans'].value ==='SuperLikesPlans'">SuperLikes </th>
                              <th class="th-heading" *ngIf="this.revenueReportForm.controls['plans'].value !=='BoostPlans' && this.revenueReportForm.controls['plans'].value !=='SuperLikesPlans' ">Plan Duration (Months)</th>
                              <th class="th-heading">Revenue</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let plans of subscriptionPlans">
                              <td>{{plans.planName}}</td>
                              <td>{{plans.childPlanId}}</td>
                              <td>{{plans.planDuration}}</td>
                              <td>{{plans.revenue}}</td>
                           </tr>
                        </tbody>
                        <tbody *ngIf="subscriptionPlans?.length == 0">
                           <tr>
                              <td colspan="4" class="no-data-available">No data available in table!</td>
                           </tr>
                        <tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="card">
               <div class="card-body">
                  <h5 class="card-title">Plan Revenue</h5>
                  <div class="mtt">
                     <form>
                       <label>Select Graphical Type &nbsp;: &nbsp;</label>
                        <select (change)="onOptionSelectedForRev($event.target.value)" class="dropDown">
                         <option value="bar">Bar</option>
                         <option value="line">Line</option>
                         <option value="pie">Pie</option>
                       </select>
                     </form>
                   </div>
                  <div class="chart-container width">
                     <canvas id="myChart"></canvas>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>