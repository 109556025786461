<div class="right_col screen-height u_scrns edit-page" role="main">
  <div class="container-fluid">
    <div class="col-sm-12 mb-4 ">
      <h1 class="text-left font-weight-bold text-uppercase text-dark">Dashboard</h1>
    </div>
    <div class="row mt-3">
      <div class="col-sm-6 col-md-3">
        <div class="card text-dark mb-3">
          <div class="card-header">Registered Users</div>
          <div class="card-body">
            <h3 class="card-title">{{ total_users }}</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="card text-dark mb-3 card-bg">
          <div class="card-header">Active Subscribers</div>
          <div class="card-body">
            <h3 class="card-title">{{ subscriptions }}</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="card text-dark mb-3">
          <div class="card-header">Inactive Profiles</div>
          <div class="card-body">
            <h3 class="card-title">{{ totalInactiveUsers}}</h3>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3">
        <div class="card text-dark mb-3 card-bg">
          <div class="card-header">Total Revenue Monthly</div>
          <div class="card-body">
            <h3 class="card-title">$ {{ totalRevenue }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Manage Accounts
          </div>
          <div class="card-body">
            <p class="card-text">Activate or deactivate user profiles</p>
            <a routerLink="/users" class="btn btn-block login-btn">Manage Accounts</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Manage Subscriptions
          </div>
          <div class="card-body">
            <p class="card-text">Create or edit subscription plans</p>
            <a routerLink="/subscription-plans" class="btn btn-block login-btn">Manage Subscriptions</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Manage Content
          </div>
          <div class="card-body">
            <p class="card-text">Update or delete app content</p>
            <a routerLink="/manage-content" class="btn btn-block login-btn">Manage Content</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            View Subscription Stats
          </div>
          <div class="card-body">
            <p class="card-text">Check Subscription Plan Data</p>
            <a routerLink="/subscription-report" class="btn btn-block login-btn">View Stats</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            View Revenue History
          </div>
          <div class="card-body">
            <p class="card-text">View Revenue Stats</p>
            <a routerLink="/revenue" class="btn btn-block login-btn">View Stats</a>
          </div>
        </div>
      </div>
    </div>
    <div class="display">
      <div class="cardForChart mr">
        <div class="card-bodyForChart">
          <h5 class="card-titleForChart">Plan Revenue</h5>
          <div class="mtt">
            <form>
              <label>Select Graphical Type &nbsp;: &nbsp;</label>
              <select (change)="onOptionSelected($event.target.value)" class="dropDown">
                <option value="bar">Bar</option>
                <option value="line">Line</option>
                <option value="pie">Pie</option>
              </select>
            </form>
          </div>
          <div class="chart-containerForChart">
            <canvas id="myChart"></canvas>
          </div>
        </div>
      </div>
      <div class="cardForChart">
        <div class="card-bodyForChart">
          <h5 class="card-titleForChart">Plan Subscription</h5>
          <div class="mtt">
            <form>
              <label>Select Graphical Type &nbsp;: &nbsp;</label>
              <select (change)="onOptionSelectedForSubs($event.target.value)" class="dropDown">
                <option value="bar">Bar</option>
                <option value="line">Line</option>
                <option value="pie">Pie</option>
              </select>
            </form>
          </div>
          <div class="chart-containerForChart">
            <canvas id="myChartForSubscription"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>