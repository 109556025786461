import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../services/storage.service';
import { environment } from '../../environments/environment';

// Here we define list of method which calls the apis


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public baseUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient, private router: Router, private storageService: StorageService, private toastr: ToastrService,) { }

  // Function to call regiser api
  userRegister(data?: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/register', data);
  }

  // Function to call login api
  loginApi(data?: any): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/users/admin-login', data);
  }

  // Function to call reset-password api
  resetPassword(id?: any, data?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/users/admin/password-update' + id, data, { observe: 'response', headers: headers })
  }

  // Function to call logout api
  userLogout(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/users/logout', id, { params, headers: headers });
  }
  
  // Function to call get user-profile api
  getSingleUser(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/users/get-specific-user/' + id, { params, headers: headers });
  }

  // Function to call update business-user api
  updateUser(id?: any, data?: any): Observable<any> {
    const body= {id,data};
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.post(this.baseUrl + '/users/user-update-by-admin', body, { headers: headers });
  }

  // Function to call changeStatus api(to update active status of an user)
   changeStatus(id?: any, status?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let body = {id,status};
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/users/user-status-update-by-admin/', body, { observe: 'response', headers: headers })
  }

  // Function to call delete user api
  deleteUser(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/users/user-delete-by-admin/' + id, { params, headers: headers });
  }

  // Function to call get user list api
  getUsersList(data?: any, subscriber: any = false) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    data.searchdata = data.search.value;
    data.column = data.order[0].column;
    data.dir = data.order[0].dir;
    data.isSubscriber = subscriber;
    return this.httpClient.get(this.baseUrl + '/users/get-all-user', { params: data, headers: headers });
  }

  getHobbies(){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/hobbies/get-all', { headers: headers });
  }

  addHobby(name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/hobbies/create', {name}, { headers: headers });
  }

  updateHobby(id:number, name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/hobbies/update/'+ id, {name}, { headers: headers });
  }

  deleteHobby(id:number){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/hobbies/delete/'+ id, { headers: headers });
  }

  getPets(){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/pets/get-all', { headers: headers });
  }

  addPets(name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/pets/create', {name}, { headers: headers });
  }
  updatePets(id:number, name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/pets/update/'+ id, {name}, { headers: headers });
  }
  deletePets(id:number){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/pets/delete/'+ id, { headers: headers });
  }

  getEducations(){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/education/get-all-education', { headers: headers });
  }

  addEducations(name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/education/education-create', {name}, { headers: headers });
  }

  updateEducations(id:number, name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/education/update-education/'+ id, {name}, { headers: headers });
  }

  deleteEducations(id:number){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/education/delete-education/'+ id, { headers: headers });
  }

  getReligions(){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/religion/get-all-religion', { headers: headers });
  }

  addReligions(name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/religion/religion-create', {name}, { headers: headers });
  }

  updateReligions(id:number, name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/religion/update-religion/'+ id, {name}, { headers: headers });
  }

  deleteReligions(id:number){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/religion/delete-religion/'+ id, { headers: headers });
  }

  getOrientations(){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.get(this.baseUrl + '/orientation/get-all', { headers: headers });
  }

  addOrientation(name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.post(this.baseUrl + '/orientation/create', {name}, { headers: headers });
  }
  updateOrientation(id:number, name:string){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + '/orientation/update/'+ id, {name}, { headers: headers });
  }
  deleteOrientation(id:number){
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
    return this.httpClient.delete(this.baseUrl + '/orientation/delete/'+ id, { headers: headers });
  }

  // Function to call get revenue api 
  getRevenueReport(month: any, year: any): Observable<any> {
    const sessionData = this.storageService.loadSessionData();
    const token = sessionData.token
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    const data:any={month,year};
    return this.httpClient.get(this.baseUrl + '/subscriber/revenue-data', { params: data, headers: headers });
  
  }
   getSubscriptionReport(month: any, year: any): Observable<any> {
    const sessionData = this.storageService.loadSessionData();
    const token = sessionData.token
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    const data:any={month,year};
    return this.httpClient.get(this.baseUrl + '/subscriber/subscription-report-data', { params: data, headers: headers });
  
  }

  // Function to call get dashBoardData api
  dashBoardData(): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.get(this.baseUrl + '/users/get/', { headers: headers });
  }

  // Function to call get subscription api
  subscriptionPlansList(data?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token;
    if(data){
    data.searchdata = data.search.value;
    data.column = data.order[0].column;
    data.dir = data.order[0].dir;
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.get(this.baseUrl + '/subscription/subscription', { params: data, headers: headers });
  }

  // Function to call delete subscription api
  deleteSubscriptionPlans(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.delete(this.baseUrl + '/subscription/subscription/' + id, { headers: headers });
  }

  // Function to call get subscription api
  detailsOfSubscriptionPlans(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.get(this.baseUrl + '/subscription/subscription-plan/' + id, { headers: headers });
  }

  // Function to call post create-subscription api
  createSubscriptionPlan(data?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({  "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.post(this.baseUrl + '/subscription/create-subscription', data, { headers: headers });
  }

  // Function to call update subscription api
  updateSubscriptionPlan(data?: any, id?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.patch(this.baseUrl + '/subscription/subscription-update/' + id, data, { headers: headers });
  }

  // Function to call post delete-perks/subscription api
  deletePerkOfSubscriptionPlans(id?: any, perkName?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.delete(this.baseUrl + '/subscription/delete-perks/subscription/' + id + '/perk/' + perkName, { observe: 'response', headers: headers })
  }

  // Function to call get subscribers list api
  getSubscriberList(data?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    data.searchdata = data.search.value;
    data.column = data.order[0].column;
    data.dir = data.order[0].dir;
    return this.httpClient.get(this.baseUrl + '/subscribers', { params: data, headers: headers });
  }

  // Function to call get subscribe-user api by userId
  getDetailsOfSubscribedUser(id?: any): Observable<any> {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams();
    return this.httpClient.get(this.baseUrl + '/subscribe-user/' + id, { headers: headers });
  }

  isRealTimeImageVerified(id?: any, data?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let body = data;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + `/users/isRealTimeImageVerified-byAdmin/${id}`, body, { observe: 'response', headers: headers })
  }

  isPrescriptionVerified(id?: any, data?: any) {
    var sessionData = this.storageService.loadSessionData();
    var token = sessionData.token
    let body = data;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` });
    const params = new HttpParams()
    return this.httpClient.patch(this.baseUrl + `/users/isPrescriptionVerified-byAdmin/${id}`, body, { observe: 'response', headers: headers })
  }

}
