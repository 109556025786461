<div class="right_col screen-height v_scrnss edit-page" role="main">
    <div class=" amenity-size">
        <div class="col-sm-12 mb-4 ">
            <h3 class="text-left">Subscribers List</h3>
        </div>
        <div class="clearfix"></div>
        <div class="vf">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <!-- <div class="card shadow mb-4"> -->
                <!-- <div>
                        <h6 class="m-0 font-weight-bold">Users List</h6>
                    </div> -->
                <div class="">
                    <table datatable [dtOptions]="dtOptions" class="table row-border hover mat-table" width="100%"
                        cellspacing="0" id="user-table">
                        <thead>
                            <tr>
                                <th style="display: none;" class="th-heading"></th>
                                <th class="th-heading">Subscriber Name</th>
                                <th class="th-heading">Email</th>
                                <th class="th-heading">Price</th>
                                <th class="th-heading">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="rows?.length != 0">
                            <tr *ngFor="let user of rows">
                                <td style="display: none;"></td>
                                <td>{{user.firstName}} {{user.lastName}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.actualPrice}}</td>
                                <td><span class="flex_wrp flex_strt drec_colomn">
                                        <a> <button class="btn btn-outline-info user-glyf"
                                                (click)="getSubscriberDetailPage(user.userId)">Details</button></a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="rows?.length == 0">
                            <tr>
                                <td colspan="3" class="no-data-available">No data available in table!</td>
                            </tr>
                        <tbody>
                    </table>
                </div>
                <!-- </div> -->
            </div>
        </div>