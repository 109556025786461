<!-- <div class="container-fluid reset_pass">
    <div class="row">
        <div class="col-sm-4">

            <label>Current Password</label>
            <form action="" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                <div class="form-group pass_show buttom-input">
                    <input type="password" formControlName="oldPassword" class="form-control" placeholder="Current Password"
                    [ngClass]="{ 'is-invalid': submitted && resetPasswordForm.controls.oldPassword.errors }">
                    <div
                    *ngIf="resetPasswordForm.controls.password.invalid && (resetPasswordForm.controls.password.dirty || resetPasswordForm.controls.password.touched)"
                    class="invalid-feedback" style="display: block;">
                    <div *ngIf="resetPasswordForm.controls.password.errors.required">
                      Password is required.
                    </div>
                  </div>
                </div>
                <label>New Password</label>
                <div class="form-group pass_show">
                    <input type="password" class="form-control" formControlName="newPassword" placeholder="New Password"
                    [ngClass]="{ 'is-invalid': submitted && resetPasswordForm.controls.newPassword.errors }">
                    <div
                    *ngIf="resetPasswordForm.controls.password.invalid && (resetPasswordForm.controls.newPassword.dirty || resetPasswordForm.controls.newPassword.touched)"
                    class="invalid-feedback" style="display: block;">
                    <div *ngIf="resetPasswordForm.controls.newPassword.errors.required">
                      Password is required.
                    </div>
                  </div>
                </div>
                <label>Confirm Password</label>
                <div class="form-group pass_show">
                    <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm Password"
                    [ngClass]="{ 'is-invalid': submitted && resetPasswordForm.controls.confirmPassword.errors }">
                    <div
                    *ngIf="resetPasswordForm.controls.confirmPassword.invalid && (resetPasswordForm.controls.confirmPassword.dirty || resetPasswordForm.controls.confirmPassword.touched)"
                    class="invalid-feedback" style="display: block;">
                    <div *ngIf="resetPasswordForm.controls.confirmPassword.errors.required">
                      Password is required.
                    </div>
                  </div>
                </div>
                <br>
                <input name="login" id="login" class="btn btn-block login-btn" type="submit" value="Submit">
            </form>
        </div>
    </div>
</div> -->

<div class="bg-white" style="min-height: 100vh;">
<div class="container-fluid ">
  <div class="row overlapSection overlapSectionMedia edit-page right_col bg-transparent change-password">
   
    <div class="col-md-3"></div>
    <!-- <div _ngcontent-uur-c68="" class="page-title"></div> -->
    <div class="col-md-6 amenity-size login-section-wrapper login-section-wrapper-media">
      <div class="main-dvi">
        <!-- <div class="brand-wrapper">
          <img src="assets/images/logo.svg" alt="logo" class="logo">
        </div> -->
        <div class="login-wrapper my-auto">
          <h3 class="text-center mb-4">Change password</h3>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group mb-4">
              <label for="password">Old Password</label>
              <input type="password" formControlName="oldPassword" id="oldPassword" class="form-control"
                placeholder="Enter your old password" [ngClass]="resetPasswordForm.controls.oldPassword">
              <div
                *ngIf="resetPasswordForm.controls.oldPassword.invalid && (resetPasswordForm.controls.oldPassword.dirty || resetPasswordForm.controls.oldPassword.touched)"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="resetPasswordForm.controls.oldPassword.errors.required">
                  Old password is required.
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label for="password">New Password</label>
              <input type="password" formControlName="newPassword" id="newPassword" class="form-control"
                placeholder="Enter your new password" [ngClass]="resetPasswordForm.controls.newPassword">
              <div
                *ngIf="resetPasswordForm.controls.newPassword.invalid && (resetPasswordForm.controls.newPassword.dirty || resetPasswordForm.controls.newPassword.touched)"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="resetPasswordForm.controls.newPassword.errors.required">
                  New password is required.
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label for="password">Password (confirm)</label>
              <input type="password" formControlName="confirmPassword" id="confirmPassword" class="form-control"
                placeholder="Enter your new password" [ngClass]="resetPasswordForm.controls.confirmPassword"
                (keyup)='checkPasswords(resetPasswordForm.controls.newPassword.value, resetPasswordForm.controls.confirmPassword.value)'>
              <span *ngIf="passwordValid == false" style="color:#FF0000">
                Password doesn't match
              </span>
              <div
                *ngIf="resetPasswordForm.controls.confirmPassword.invalid && (resetPasswordForm.controls.confirmPassword.dirty || resetPasswordForm.controls.confirmPassword.touched)"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="resetPasswordForm.controls.confirmPassword.errors.required">
                  Password is required.
                </div>
              </div>
            </div>
            <input name="login" id="login" class="btn btn-block login-btn" type="submit" value="Submit">
          </form>
          <!-- <a routerLink="/forgot-password" class="forgot-password-link">Forgot password?</a>
            
          <p class="login-wrapper-footer-text">Don't have an account? <a routerLink="/register"
              class="text-reset">Register here</a></p> -->
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-6 px-0 d-none d-sm-block">
      <img src="assets/images/login.jpg" alt="login image" class="login-img">
    </div> -->
  </div>
</div>
</div>