<div class="right_col screen-height v_scrnss edit-page" role="main">
   <div class=" amenity-size">
      <div>
         <div>
            <h1 class="text-left font-weight-bold text-uppercase text-dark">Subscription History</h1>
         </div>
         <div class="container-fluid ">
            <div class="row justify-content-center dash_centr_bx dash_box_wrapp">
               <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">

                  <div class="small-box bg-info dash_box dash_bx1">
                     <div class="inner">
                        <h3>{{showSubscribers}}</h3>
                        <p>Total Subscribers</p>
                        <span class="icon"> <i class="fa fa-user-plus"></i></span>
                     </div>
                  </div>
               </div>
               <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                  <div class="small-box bg-success dash_box dash_bx2">
                     <div class="inner">
                        <h3>{{totalUser}}</h3>
                        <p>Total Users</p>
                        <span class="icon"><i class='fa fa-users'></i></span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="revv_tab_wrap">
               <form [formGroup]="subscriptionReportForm">
                  <div class="rev_tabb">
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="month" (change)="filterAllPlans($event.target.value)">
                           <option *ngFor="let month of monthName; let id =index" value={{id+1}}>{{month}}</option>
                        </select>
                     </div>
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="year" (change)="filterAllPlans($event.target.value)">
                           <option *ngFor="let year of years;let id = index" value={{year}}>{{year}}</option>
                        </select>
                     </div>
                     <div class="revv_bx">
                        <select class="log_btn form-control custom-datepicker text-white pointMe"
                           formControlName="plans" (change)="filterPlans($event.target.value)">
                           <option *ngFor="let plan of dropdownPlanIds; let id =index" value={{plan}}>{{plan}}</option>
                        </select>
                     </div>
                  </div>
               </form>
               <div class="subs_table_wrap subs_table_wdth">
                  <div class="table-responsive">
                     <table class="table table-bordered subs_table">
                        <thead>
                           <tr>
                              <th>Plan Name</th>
                              <th>SubscriptionPlan Name</th>
                              <th>Plan Duration</th>
                              <th>Subscribers</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let eachSubscriber of subscribers">
                              <td>{{eachSubscriber.planName}}</td>
                              <td>{{eachSubscriber.childPlanId}}</td>
                              <td>{{eachSubscriber.planDuration}}</td>
                              <td>{{eachSubscriber.subscribers}}</td>
                           </tr>
                        </tbody>
                        <tbody *ngIf="subscribers?.length == 0">
                           <tr>
                              <td colspan="4" class="no-data-available">No data available in table!</td>
                           </tr>
                        <tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="card">
               <div class="card-body">
                  <h5 class="card-title">Plan Subscription</h5>
                  <div class="mtt">
                     <form>
                        <label>Select Graphical Type &nbsp;: &nbsp;</label>
                        <select (change)="onOptionSelectedForRev($event.target.value)" class="dropDown">
                           <option value="bar">Bar</option>
                           <option value="line">Line</option>
                           <option value="pie">Pie</option>
                        </select>
                     </form>
                  </div>
                  <div class="chart-container width">
                     <canvas id="myChart"></canvas>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
