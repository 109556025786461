import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedInAdmin = JSON.parse(localStorage.getItem('admin'));
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        if (!loggedInUser && !loggedInAdmin) {
                this.router.navigate(['login']);
                return false;
        } else {
            if (state.url === "/") {
                this.router.navigate(['dashboard']);
                return true;
            } else {
                return true;
            }
        }
    }
}