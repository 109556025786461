import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { SEXUAL_INTEREST } from '../../constants/code-constants'
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {
  public userData;
  private Pets: any;
  private Sexorientations: any;
  private Hobbies: any;
  public sexInterest: any;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.getHobbies();
    this.getOrientations();
    this.getUser(id);
    this.sexInterest=SEXUAL_INTEREST;
  }


  getUser(id) {
    this.authenticationService.getSingleUser(id).subscribe(res => {
      this.userData = res.user;
      if(res.user.interest?.length){
        const hobbyNameArray = res.user.interest.map(id => this.getHobbyNameById(id));
        this.userData.interest=hobbyNameArray;
      }
      if(res.user.sexualOrientation?.length){
        const orientationNameArray = res.user.sexualOrientation.map(id => this.getOrientationNameById(id));
        this.userData.sexualOrientation=orientationNameArray;
      }
      if(res.user.sexualInterest){
        this.userData.sexualInterest=this.sexInterest[res.user.sexualInterest-1].name;
      }
    })
  }
  
  getHobbies() {
    this.authenticationService.getHobbies().subscribe((res: any) => {
      if (res.hobbies && res.hobbies.length > 0) {
        this.Hobbies=res.hobbies
        .map(({name, hobbiesId}) => ({name, id:hobbiesId}));
      }
    })
  }

  getOrientations() {
    this.authenticationService.getOrientations().subscribe((res: any) => {
      if (res.orientations && res.orientations.length > 0) {
        this.Sexorientations=res.orientations
        .map(({name, orientationId}) => ({name, id:orientationId}));
      }
    });
  };


  getHobbyNameById(id: number): string {
    const hobby = this.Hobbies.find(h => h.id === id);
    return hobby ? hobby.name : '';
  }
  
  getOrientationNameById(id: number): string {
    const orientation = this.Sexorientations.find(o => o.id === id);
    return orientation ? orientation.name : '';
  }
  
}
