import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SidebarComponent implements OnInit {
  public hide = false;
  public happyHours = false
  loggedInUser: any;
  categoryName: any;
  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(localStorage.getItem('userRole') == 'admin'){
      this.loggedInUser = JSON.parse(localStorage.getItem('admin'));
      this.hide = true;
    } else if(localStorage.getItem('userRole') == 'user'){
      this.loggedInUser = JSON.parse(localStorage.getItem('user'));
    }
  }

  onResize(event) {
    document.getElementsByTagName('body')[0].classList.remove('sidebar-open');
  }
}
