import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  show_nav: boolean = false;

  constructor(private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute) { }
  id
  ngOnInit(): void {
    var currentAdmin = JSON.parse(localStorage.getItem('admin'));
    var currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentAdmin) {
      this.id = (currentAdmin && currentAdmin.id) ? currentAdmin.id : '';
    }
    if (currentUser) {
      this.id = (currentUser && currentUser.id) ? currentUser.id : '';
    }
  }

  logout() {
    var currentAdmin = JSON.parse(localStorage.getItem('admin'));
    var currentUser = JSON.parse(localStorage.getItem('user'));
    const adminId = (currentAdmin && currentAdmin.id) ? currentAdmin.id : '';
    const userId = (currentUser && currentUser.id) ? currentUser.id : '';
    if (currentAdmin) {
          localStorage.removeItem('admin')
          localStorage.removeItem('userRole')
          this.toastr.success("Admin Successfully Logged Out");
          this.router.navigate(['/login']);
        } 
     else {
      this.authenticationService.userLogout(userId).subscribe((res) => {
        if (res && res.status == 200) {
          localStorage.removeItem('user')
          localStorage.removeItem('userRole')
          this.toastr.success(res.message);
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(res.message, 'Unable to logout!', { progressBar: true });
        }
      })
    }

  }

  changePassword() {
    this.router.navigate(['/change-password/', this.id]);
  }

  showNav() {
    document.getElementsByTagName('body')[0].classList.toggle('sidebar-open');
  }
}
