<div>
	<div class="left_col scroll-view sticky">
		<div class="navbar nav_title" style="border: 0; background-color: #B4CD2A;">
			<a href="index.html" class="site_title">
				<div class="brand-wrapper your-class">
					<img src="assets/images/logo_final1_white.png" alt="logo" class="logo" width="150" height="500" >
				</div>
			</a>
		</div>
		<div class="clearfix"></div>

		<br>

		<div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
			<div class="menu_section active">
				<!-- <h3>General</h3> -->
				<ul class="nav side-menu">
					<li>
						<a routerLink="/dashboard"><i class="fa fa-dashboard" aria-hidden="true"></i>Dashboard
						</a>
					</li>
					<li>
						<a routerLink="/users"><i class="fa fa-user" aria-hidden="true"></i>Users </a>
					</li>
					<li>
						<a routerLink="/subscription-plans"><i class="fa fa-building-o" aria-hidden="true"></i>Subscription	Plans</a>
					</li>
					<li>
						<a routerLink="/manage-content"><i class="fa fa-cogs" aria-hidden="true"></i>Manage Content</a>
					</li>
					<!-- <li *ngIf="hide">
						<a href="/subscriber-list"><i class="fa fa-users"></i>Subscriber</a>
					</li> -->
					<li>
						<a routerLink="/revenue"><i class="fa fa-money" aria-hidden="true"></i>Revenue</a>
					</li>
					<li>
						<a routerLink="/subscription-report"><i class="fa fa-file" aria-hidden="true"></i>Subscription Report</a>
					</li>
					<li>
						<a routerLink="/verify-realtimeImage"><i class="fa fa-user" aria-hidden="true"></i>Verify Medical Reciept </a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>