<div class="right_col screen-height v_scrnss edit-page" role="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 mb-4 ">
                <h1 class="text-left font-weight-bold text-uppercase text-dark">Subscription Plans</h1>
            </div>
            <div class="col-sm-6">
                <div class="text-right">
                    <a>
                        <button type="button" class="add-btn"  routerLink="/subscription-plan">
                            <i class="fas fa-plus"></i> Add
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptions" class="table table-bordered table-hover" width="100%"
                            cellspacing="0" id="user-table">
                            <thead>
                                <tr>
                                    <th style="display: none;" class="th-heading"></th>
                                    <th class="th-heading">Plan Name</th>
                                    <th class="th-heading">Plan Id</th>
                                    <th class="th-heading">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="rows?.length != 0">
                                <tr *ngFor="let plans of rows">
                                    <td style="display: none;"></td>
                                    <td>{{plans.planName}}</td>
                                    <td>{{plans.planId}}</td>
                                    <td>
                                        <div class="btn-group" role="group">
                                            <!-- <button class="btn btn-outline-success user-glyf"
                                                (click)="getSubscriptionId(plans.planId)">
                                                <i class="fas fa-edit"></i> Edit
                                            </button> -->
                                            <button class="btn btn-outline-info user-glyf"
                                                (click)="getSubscriptionPlansDetailPage(plans.planId)">
                                                <i class="fas fa-info-circle"></i> Details
                                            </button>
                                            <!-- <button class="btn btn-outline-danger user-glyf"
                                                (click)="deleteModelDialog(plans.planId)">
                                                <i class="fas fa-trash"></i> Remove
                                            </button> -->
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="rows?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data available in table!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" *ngIf="showDialog == 1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form>
                            <div>
                                <div class="text-center">
                                    <h2>Are you sure, you want to delete?</h2>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-default smingl_button" (click)="closeModalDialog()"
                                style="border-color: #117a8b;">Cancel</button>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-right">
                                <input type="submit" class="btn btn-danger btn-lg smingl_button" value="Delete"
                                    (click)="deleteSubscriptionPlans(deleteId)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>