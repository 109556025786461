import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OPTION } from 'src/app/modals/option.module';
import { CodeConstants } from 'src/app/constants/code-constants';

@Component({
  selector: 'app-edit-content',
  templateUrl: './edit-content.component.html',
  styleUrls: ['./edit-content.component.scss']
})
export class EditContentComponent implements OnInit {
  private loggedInAdmin: any;
  public options: OPTION[]=[];
  public contentName:string='';
  selectedOption:OPTION;
  public confirmOptionName: string = '';
  public confirmOptionId: number = 0;
  public showAddModal: boolean = false;
  public showDeleteModal: boolean = false;
  public showEditModal: boolean = false;
  private codeConstants=CodeConstants;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.contentName = this.route.snapshot.paramMap.get('name');
    this.loggedInAdmin = JSON.parse(localStorage.getItem('admin'));

    this.fetchcontentDataById();
  }

  confirmAddOption(optionName) {
    if(!optionName){
      return;
    }
    this.confirmOptionName = optionName;
    this.showAddModal=true;
  }

  confirmDeleteOption(optionId) {
    this.confirmOptionId = optionId;
    this.showDeleteModal=true;
  }

  openEditModal(option:any){
    this.selectedOption=option;
    this.showEditModal=true;
  }

  addContentOption(name: string) {
    if (this.contentName === this.codeConstants.HOBBIES) {
      this.authenticationService.addHobby(name).subscribe((res:any)=>{
        if(res.status === 'success'){
        this.options.push({name:res.hobbies.name, id:res.hobbies.hobbiesId});
        this.showAddModal=false;
      }
      },(error)=>{
        this.toastr.error('Already Exists');
        this.showAddModal=false;
        })
    }else if(this.contentName === this.codeConstants.ORIENTATIONS){
      this.authenticationService.addOrientation(name).subscribe((res:any)=>{
        if(res.status === 'success'){
        this.options.push({name:res.orientation.name, id:res.orientation.orientationId});
        this.showAddModal=false;
      }
      },(error)=>{
        this.toastr.error('Already Exists');
        this.showAddModal=false;
        }
        )
    }else if(this.contentName === this.codeConstants.PETS){
      this.authenticationService.addPets(name).subscribe((res:any)=>{
        if(res.status === 'success'){
        this.options.push({name:res.pet.name, id:res.pet.petId});
        this.showAddModal=false;
      }
    },(error)=>{
      this.toastr.error('Already Exists');
      this.showAddModal=false;
    }
      )
    }else if(this.contentName === this.codeConstants.EDUCATIONS){
      this.authenticationService.addEducations(name).subscribe((res:any)=>{
        if(res.status === 'success'){
        this.options.push({name:res.education.name, id:res.education.educationId});
        this.showAddModal=false;
      }
    },(error)=>{
      this.toastr.error('Already Exists');
      this.showAddModal=false;
    }
      )
    }
    else if(this.contentName === this.codeConstants.RELIGIONS){
      this.authenticationService.addReligions(name).subscribe((res:any)=>{
        if(res.status === 'success'){
        this.options.push({name:res.religion.name, id:res.religion.religionId});
        this.showAddModal=false;
      }
    },(error)=>{
      this.toastr.error('Already Exists');
      this.showAddModal=false;
    }
      )
    }
    else{
      this.toastr.error('No Such Content available');
      this.showAddModal=false;
    }
  }
  
  editContentOption(option: string) {
    if(!option){
      return;
    }
    if (this.contentName === this.codeConstants.HOBBIES) {
    this.authenticationService.updateHobby(this.selectedOption.id,option).subscribe((res:any)=>{
      if(res.status === 'success'){
        const optionIndex = this.options.findIndex(option => option.id === this.selectedOption.id);
        const updatedHobby = {name:res.hobby.name, id:res.hobby.hobbiesId}
        this.options.splice(optionIndex, 1, updatedHobby);
        this.showEditModal=false;
      }
    })
  }else if(this.contentName === this.codeConstants.ORIENTATIONS){
    this.authenticationService.updateOrientation(this.selectedOption.id,option).subscribe((res:any)=>{
      if(res.status === 'success'){
        const optionIndex = this.options.findIndex(option => option.id === this.selectedOption.id);
        const updatedOrientation = {name:res.orientation.name, id:res.orientation.orientationId}
        this.options.splice(optionIndex, 1, updatedOrientation);
        this.showEditModal=false;
      }
    })
  }else if(this.contentName === this.codeConstants.PETS){
    this.authenticationService.updatePets(this.selectedOption.id,option).subscribe((res:any)=>{
      if(res.status === 'success'){
        const optionIndex = this.options.findIndex(option => option.id === this.selectedOption.id);
        const updatedpet = {name:res.pet.name, id:res.pet.petId}
        this.options.splice(optionIndex, 1, updatedpet );
        this.showEditModal=false;
      }
    })
  }else if(this.contentName === this.codeConstants.EDUCATIONS){
    this.authenticationService.updateEducations(this.selectedOption.id,option).subscribe((res:any)=>{
      if(res.status === 'success'){
        const optionIndex = this.options.findIndex(option => option.id === this.selectedOption.id);
        const updatedEducation = {name:res.education.name, id:res.education.educationId}
        this.options.splice(optionIndex, 1, updatedEducation );
        this.showEditModal=false;
      }
    })
  }
  else if(this.contentName === this.codeConstants.RELIGIONS){
    this.authenticationService.updateReligions(this.selectedOption.id,option).subscribe((res:any)=>{
      if(res.status === 'success'){
        const optionIndex = this.options.findIndex(option => option.id === this.selectedOption.id);
        const updatedReligion = {name:res.religion.name, id:res.religion.religionId}
        this.options.splice(optionIndex, 1, updatedReligion );
        this.showEditModal=false;
      }
    })
  }
  else{
    this.showEditModal=false;
    this.toastr.error("Unable to Update");
    }
  }

  deleteContentOption(optionId: number) {
    if (this.contentName === this.codeConstants.HOBBIES) {
      this.authenticationService.deleteHobby(optionId).subscribe((res)=>{
        if(res){
          const optionIndex = this.options.findIndex(option => option.id === optionId);
          this.options.splice(optionIndex, 1);
          for (let i = optionIndex; i < this.options.length; i++) {
            this.options[i].id = i;
          }
          this.showDeleteModal=false;
          this.toastr.success("Hobby deleted successfully.");
        }else{
          this.showDeleteModal=false;
        this.toastr.error("Unable to Delete");
      }
    })
  }else if(this.contentName === this.codeConstants.ORIENTATIONS){
    this.authenticationService.deleteOrientation(optionId).subscribe((res)=>{
      if(res){
        const optionIndex = this.options.findIndex(option => option.id === optionId);
        this.options.splice(optionIndex, 1);
        for (let i = optionIndex; i < this.options.length; i++) {
          this.options[i].id = i;
        }
        this.showDeleteModal=false;
        this.toastr.success("Orientation deleted successfully.");
      }else{
        this.showDeleteModal=false;
        this.toastr.error("Unable to Delete");
    }
  })
  }else if(this.contentName === this.codeConstants.EDUCATIONS){
    this.authenticationService.deleteEducations(optionId).subscribe((res)=>{
      if(res){
        const optionIndex = this.options.findIndex(option => option.id === optionId);
        this.options.splice(optionIndex, 1);
        for (let i = optionIndex; i < this.options.length; i++) {
          this.options[i].id = i;
        }
        this.showDeleteModal=false;
        this.toastr.success("Education deleted successfully.");
      }else{
        this.showDeleteModal=false;
        this.toastr.error("Unable to Delete");
    }
  })
  }
  else if(this.contentName === this.codeConstants.RELIGIONS){
    this.authenticationService.deleteReligions(optionId).subscribe((res)=>{
      if(res){
        const optionIndex = this.options.findIndex(option => option.id === optionId);
        this.options.splice(optionIndex, 1);
        for (let i = optionIndex; i < this.options.length; i++) {
          this.options[i].id = i;
        }
        this.showDeleteModal=false;
        this.toastr.success("Religion deleted successfully.");
      }else{
        this.showDeleteModal=false;
        this.toastr.error("Unable to Delete");
    }
  })
  }
  else{
    this.toastr.error('No Such Content available');
    this.showDeleteModal=false;
  }
  }

  closeDeleteModal(){
    this.showDeleteModal=false;
  }

  closeAddModal(){
    this.showAddModal=false;
  }

  closeEditModal(){
    this.showEditModal=false;
  }

  fetchcontentDataById() {
    if (this.contentName === this.codeConstants.HOBBIES) {
      this.authenticationService.getHobbies().subscribe((res: any) => {
        this.options=res.hobbies
        .map(({name, hobbiesId}) => ({name, id:hobbiesId}));
      })
    }
    else if(this.contentName === this.codeConstants.ORIENTATIONS){
      this.authenticationService.getOrientations().subscribe((res: any) => {
        this.options=res.sexOrientation
        .map(({name, orientationId}) => ({name, id:orientationId}));
      })
    }
    else if(this.contentName === this.codeConstants.PETS){
      this.authenticationService.getPets().subscribe((res: any) => {
        this.options=res.pets
        .map(({name, petId}) => ({name, id:petId}));
      })
    }
    else if(this.contentName === this.codeConstants.EDUCATIONS){
      this.authenticationService.getEducations().subscribe((res: any) => {
        this.options=res.education
        .map(({name, educationId}) => ({name, id:educationId}));
      })
    }
    else if(this.contentName === this.codeConstants.RELIGIONS){
      this.authenticationService.getReligions().subscribe((res: any) => {
        this.options=res.religion
        .map(({name, religionId}) => ({name, id:religionId}));
      })
    }
     else {
      this.toastr.error("Error while fetching the requested content");
    }
  }

}