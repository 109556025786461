import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormArray, Form, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { FEATURES , SCHEDULE} from '../../interfaces/interface';

@Component({
  selector: 'app-create-subscription-plans',
  templateUrl: './create-subscription-plans.component.html',
  styleUrls: ['./create-subscription-plans.component.scss']
})

// This component is for creating subscription plan by admin
export class CreateSubscriptionPlansComponent implements OnInit {
  selected = true;
  subscriptionPlanForm: FormGroup;
  subscriptionPayload: any;
  id: any;
  loggedInAdmin: any;
  name = 'Dynamic Add Fields';
  subscriptionPlanData: any;
  totalRow: any;
  values = [];
  dataList: Array<any> = [];
  perks: any = [];
  schedule: { branch: '' };
  selectedQuantity = "Select vailidity";
  planSchedule:Array<SCHEDULE>=[];
  planFeatures:Array<FEATURES>=[];
  image:Object = null;
  multipeImages:any=[];
  Periods:any=[];
  constructor(private authenticationService: AuthenticationService, private fb: FormBuilder, private router: Router,
    private toastr: ToastrService, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) {
  }

  // Function to call when page loads
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loggedInAdmin = JSON.parse(localStorage.getItem('admin'));
    this.Periods =  [
      { id: 1, name: "24 Hours" },
      { id: 2, name: "1 Week" },
      { id: 3, name: "1 Month" },
      { id: 3, name: "3 Months" },
      { id: 4, name: "6 Months" },
    ]

    this.subscriptionPlanForm = this.fb.group({
      planName: [null, Validators.required],
      planId: [null, Validators.required],
      eachPlanId: [null, Validators.required],
      planPrice: [null, Validators.required],
      planValidity: ['1 Month', Validators.required],
      perks: [null, Validators.required],
      description: [null, Validators.required],
      image:[null, Validators.required],
      active: [true, Validators.required]
    })
  }

  // This function is called when submit the form with new values
  onSubmit() {
     const formData = new FormData();
     if( !this.subscriptionPlanForm.controls.planName.value || !this.subscriptionPlanForm.controls.active.value){
       return this.toastr.error('Please add the Required Fields');
     }
     else if(!this.planSchedule.length){
      return this.toastr.error('Please add atleast one Plan Schedule');
     }
     else if( !this.planFeatures.length){
      return this.toastr.error('Please add atleast one Plan Description');
     }
     for(const img of this.multipeImages){
     formData.append('files',img);
   }
    
    this.subscriptionPayload = {
      "planName": this.subscriptionPlanForm.controls.planName.value,
      "planId": this.subscriptionPlanForm.controls.planId.value,
      "planSchedule": this.planSchedule,
      "planFeatures":this.planFeatures,
      "active": this.subscriptionPlanForm.controls.active.value
    }
    const payload = JSON.stringify(this.subscriptionPayload)
    formData.append('subscriptionPayload', payload);
    this.authenticationService.createSubscriptionPlan(formData).subscribe((res) => {
      if (res) {
        this.toastr.success("Subscription plan created successfully.");
        this.router.navigateByUrl('/subscription-plans');
      } else {
        this.toastr.error("Error while creating subscription Plan.");
      }

    },
    (error)=>{
      console.log("error",error);
      this.toastr.error(error.error.message);
    }
    )
  }

  public addPlan(){
    const plans = {
      planPrice: this.subscriptionPlanForm.controls.planPrice.value ,
      planValidity: this.subscriptionPlanForm.controls.planValidity.value,
      planId: this.subscriptionPlanForm.controls.eachPlanId.value
    }
    if(this.subscriptionPlanForm.controls.planPrice.value <0 || !this.subscriptionPlanForm.controls.planValidity.value || !this.subscriptionPlanForm.controls.eachPlanId.value ){
      return this.toastr.error('Please add the required Plan Schedule fields');
    }
    if(!this.isPlanExist(plans.planValidity)){
    this.planSchedule.push(plans);
    this.subscriptionPlanForm.controls.planPrice.setValue('');
    this.subscriptionPlanForm.controls.planValidity.setValue('');
    this.subscriptionPlanForm.controls.eachPlanId.setValue('');
    }
  }  


  public addDescription(){
    const description = {
      perks: this.subscriptionPlanForm.controls.perks.value ,
      description: this.subscriptionPlanForm.controls.description.value,
      image:this.subscriptionPlanForm.controls.image.value
    }
    if(!this.subscriptionPlanForm.controls.perks.value || !this.subscriptionPlanForm.controls.description.value ){
      return this.toastr.error('Please add the required Plan Features fields');
    }
    if(!this.subscriptionPlanForm.controls.image.value){
      return this.toastr.error('Please upload image');
    }
    this.planFeatures.push(description);
    this.subscriptionPlanForm.controls.perks.setValue(null);
    this.subscriptionPlanForm.controls.description.setValue(null);
    this.subscriptionPlanForm.controls.image.setValue(null);
  } 

  public uploadImage(event: any){
    this.image =event.target.files[0];
    this.multipeImages.push(this.image);
  }

  public clearPlan(index: number){
     this.planSchedule.splice(index,1);

  }

  public clearDescription(index: number){
    this.planFeatures.splice(index,1);

 }

 public isPlanExist(validity: number){
  for(const plans of this.planSchedule){
    if(plans.planValidity === validity){
      this.toastr.error('Plan Validity already exists');
      return true;
    }
  }
}

  // Function to create new perks for a plan
  createPerks() {
    let locControl: any = <FormArray>this.subscriptionPlanForm.controls['perks'];
    let EmptyExist = locControl.value.some(function (data) {
      return (!data.perk);
    })
    if (!EmptyExist) {
      locControl.push(this.initPerks());
    }
  }

  initPerks() {
    return this.fb.group({
      "perk": ["", Validators.compose([Validators.required])],
    });
  }

  get perkControls() {
    return (this.subscriptionPlanForm.get('perks') as FormArray).controls;
  }

  isOnlyPerk(index: number): boolean {
    if (index === 0) {
      return true;
    }
    if (this.perkControls.length === 1 && index === 0) {
      return true;
    }
    if (this.perkControls.length === 2 && !this.perkControls[index].value.perk) {
      return true;
    }
    if (index = 0 && this.perkControls.length === 2 && !this.perkControls[1].value.perk) {
      return true;
    }
    return false;
  }

  // Function to delete a perk row of a perk
  deleteRowForPerks(index: number) {
    const control = <FormArray>this.subscriptionPlanForm.controls['perks']
    if (control != null) {
      this.totalRow = control.value.length;
    }
    if (this.totalRow > 1) {
      control.removeAt(index);
    } else {
      this.toastr.error("At least one perk is mendatory!");
      return false;
    }
  }

  // Function to delete a plan
  deleteRowForOffersPlans(index: number) {
    const control = <FormArray>this.subscriptionPlanForm.controls['offerplans'];
    if (control != null) {
      this.totalRow = control.value.length;
    }
    if (this.totalRow > 1) {
      control.removeAt(index);
    } else {
      this.toastr.error("At least one offer plan is mendatory!");
      return false;
    }
  }

}