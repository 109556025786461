import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { SEXUAL_INTEREST } from '../../constants/code-constants'
import { ToastrService } from 'ngx-toastr';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-verify-real-time-image',
  templateUrl: './verify-real-time-image.component.html',
  styleUrls: ['./verify-real-time-image.component.scss']
})
export class VerifyRealTimeImageComponent implements OnInit { 
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public rows = [];
  public columns = [];
  page: Number;
  deleteId
  display = 'none';
  zIndex
  showDialog
  dataTablesPara
  subscribeLabel = 'Subscribers';
  public searchKey = '';
  public subscriberSearchKey = '';
  public checkedSubscribed = false;
  public totalRecords: number = 0;
  totalRecords$ = new BehaviorSubject<number>(0);

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService) { }

  // This function is called when page loads
  ngOnInit(): void {
    this.loadInit();
  }

    loadInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        serverSide: true,
        processing: false,
        searching: true,
        ordering: true,
        responsive: true,
        
        ajax: (dataTablesParameters: any, callback) => {
          let subs = localStorage.getItem('subscriberSearchKey') == 'true' ? localStorage.getItem('subscriberSearchKey') : ''
          let localSearchKeyData = localStorage.getItem("searchKey")
          this.searchKey = dataTablesParameters.search.value
          dataTablesParameters.search.value = localSearchKeyData ? localSearchKeyData : (this.searchKey ? this.searchKey : '')
          this.dataTablesPara = dataTablesParameters
          this.checkedSubscribed = Boolean(subs)
          this.authenticationService.getUsersList(dataTablesParameters, Boolean(subs)).subscribe(
            (resp: any) => {
              if (resp.statusCode === 200) {
                this.rows = resp.user[0].data;
                localStorage.removeItem('searchKey')
                localStorage.removeItem('subscriberSearchKey')
                this.totalRecords=resp.user[0].metadata[0]?.total? resp.user[0].metadata[0]?.total : 0;
                this.totalRecords$.next(this.totalRecords);
                this.totalRecords$.subscribe((totalRecords)=> {
                  callback({
                    recordsTotal:totalRecords,
                    recordsFiltered: totalRecords,
                    data: []
                  })
                });
              }
            }, err => {
              console.log('Error', err)
            }
          );
        }
      };
    }

  // Function to filter users who purchased a subscription plan
  checked(event: any) {
    let subs = false
    this.subscribeLabel = "Subscribers"
    if (event && event.target && event.target.checked) {
      subs = true
      this.subscribeLabel = "Subscribers"
    }
    this.subscriberSearchKey = String(subs)
    localStorage.setItem('subscriberSearchKey', this.subscriberSearchKey);
    this.authenticationService.getUsersList(this.dataTablesPara, subs).subscribe(
      (resp: any) => {
        if (resp) {
          this.rows = resp.user[0].data;
          this.totalRecords=resp.user[0].metadata[0]?.total? resp.user[0].metadata[0]?.total : 0;
          this.totalRecords$.next(this.totalRecords);
        }
      })
  }

  // Function to delete user
  deleteUser(id) {
    const userId = id;
    this.display = 'none';
    this.authenticationService.deleteUser(userId).subscribe((res) => {
      const index = this.rows.findIndex(x => x._id == userId);
      this.rows.splice(index, 1)
      let subs = localStorage.getItem('subscriberSearchKey') == 'true' ? localStorage.getItem('subscriberSearchKey') : 'false';
      this.authenticationService.getUsersList(this.dataTablesPara, subs).subscribe(
        (resp: any) => {
          if (resp) {
            this.rows = resp.user[0].data;
            this.totalRecords=resp.user[0].metadata[0]?.total? resp.user[0].metadata[0]?.total : 0;
            this.totalRecords$.next(this.totalRecords);
          }
        })
    })
  }

  // Function to get user details and also redirect to user page
  getUserId(id) {
    this.router.navigateByUrl('/user/' + id);
  }

  // Function to change active status of an user
  changeStatus(id: any, event: any) {
    let status = {
      'isPrescriptionVerified': event
    }
     this.authenticationService.isPrescriptionVerified(id, status).subscribe((res: any) => {
      if (res.body.statusCode === 200) {
      
        let subs = localStorage.getItem('subscriberSearchKey') == 'true' ? localStorage.getItem('subscriberSearchKey') : 'false';
        this.authenticationService.getUsersList(this.dataTablesPara, subs).subscribe(
        (resp: any) => {
          if (resp) {
            this.rows = resp.user[0].data;
            this.totalRecords=resp.user[0].metadata[0]?.total? resp.user[0].metadata[0]?.total : 0;
            this.totalRecords$.next(this.totalRecords);
          }
        })

        this.toastr.success(res.body.message);
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  deleteModelDiallog(deleteId) {
    this.deleteId = deleteId;
    this.display = 'block'; //Set block css
    this.zIndex = '0';
    this.showDialog = 1
  }


  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
  }

  getUsersDetailPage(userId) {
    localStorage.setItem('searchKey', this.searchKey)
    localStorage.setItem('subscriberSearchKey', this.subscriberSearchKey)
    this.router.navigateByUrl('/user-profile/' + userId);
  }

  getUsersEditPage(userId) {
    localStorage.setItem('searchKey', this.searchKey)
    localStorage.setItem('subscriberSearchKey', this.subscriberSearchKey)
    this.router.navigateByUrl('/user/' + userId);
  }

}
