<div class="right_col screen-height v_scrnss v_cm edit-page" role="main">
    <div class="col-md-12 amenity-size login-wrapper">
        <div class="text-left">
            <h3 class="mb-4"><a routerLink="/subscription-plans"><i class="fa fa-arrow-left mr-2"></i></a>Edit
                Subscription
                plan</h3>
        </div>
        <form [formGroup]="subscriptionPlanForm">
            <div class="form-group formups">
                <div class="form-group">
                    <label for="name">Plan Name<span class="required"> * </span></label>
                    <input type="text" class="form-control" placeholder="Enter Plan Name" formControlName="planName"
                        required disabled>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planName').invalid && (subscriptionPlanForm.get('planName').dirty || subscriptionPlanForm.get('planName').touched)">Please
                        enter a plan name</mat-error>
                </div>
                <div class="form-group">
                    <label>Plan Id<span class="required"> * </span></label>
                    <input type="text" class="form-control" placeholder="Enter Plan Id" formControlName="planId"
                        required disabled>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planId').invalid && (subscriptionPlanForm.get('planId').dirty || subscriptionPlanForm.get('planId').touched)">Please
                        enter a plan id</mat-error>
                </div>
                <!-- <div class="form-group">
                    <label for="likes">Likes<span class="required"> * </span></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Likes</span>
                        </div>
                        <input type="number" class="form-control" placeholder="Enter Likes" formControlName="like"
                            required>
                    </div>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('like').invalid && (subscriptionPlanForm.get('like').dirty || subscriptionPlanForm.get('like').touched)">Please
                        enter a valid number of likes</mat-error>
                </div> -->
                <!-- <div class="form-group">
                    <label for="price">Plan Price<span class="required"> * </span></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Rs</span>
                        </div>
                        <input type="number" id="plan-price" class="form-control" placeholder="Enter Plan Price"
                            formControlName="planPrice" required>
                    </div>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planPrice').invalid && (subscriptionPlanForm.get('planPrice').dirty || subscriptionPlanForm.get('planPrice').touched)">Please
                        enter a valid plan price</mat-error>
                </div>
                <div class="form-group">
                    <label for="planValidity">Plan Validity<span class="required"> * </span></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Days</span>
                        </div>
                        <input type="number" id="plan-validity" class="form-control" placeholder="Enter Plan Validity"
                            formControlName="planValidity" required>
                    </div>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planValidity').invalid && (subscriptionPlanForm.get('planValidity').dirty || subscriptionPlanForm.get('planValidity').touched)">Please
                        enter a valid plan validity</mat-error>
                </div> -->
                <label for="name">Plan Schedule<span class="required"> * </span></label>
                <div class="table-responsive d-flex mb-2">
                <table class="plain_table">
                    <thead>
                        <tr>
                            <th>Plan Price</th>
                            <th>Plan Validity</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Rs</span>
                                </div>
                                <input type="number" class="form-control" placeholder="Enter Plan Price"
                                    formControlName="planPrice" style="user-select: all;" required>
                            </div>
                            </td>
                            <td><div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Months</span>
                                </div>
                                <input type="number" class="form-control" placeholder="Enter Plan Validity"
                                    formControlName="planValidity"  required>
                            </div>
                            </td>
                        </tr>
                        <tr *ngFor="let plans of planSchedule; let i=index">
                            <td><div class="input-group">
                                <span class="index-text">{{i+1}}.</span>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Rs</span>
                                </div>
                                <input type="number" id="plan-price{{i}}" class="form-control" placeholder="Enter Plan Price"
                                    value="{{plans.planPrice}}" (change)="editPlanSchedule($event,i)">
                            </div>
                            <td><div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Months</span>
                                </div>
                                <input type="number" id="plan-validity{{i}}" class="form-control" placeholder="Enter Plan Validity"
                                value="{{plans.planValidity}}" (change)="editPlanSchedule($event,i)" >
                                <button (click)="clearPlan(i)">Delete</button>
                            </div>
                            
                        </tr>
                    </tbody>
                </table>
                <div class="btn-perks">
                    <button type="button" class="btn btn-block btn-success" (click)="addPlan()">ADD PERKS</button>
                </div>
                </div>

                <div class="table-responsive d-flex mb-2">
                    <table class="plain_table">
                        <thead>
                            <tr>
                                <th>Heading</th>
                                <th>Description</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Heading"
                                        formControlName="heading" required>
                                </div>
                                <!-- <mat-error
                                    *ngIf="subscriptionPlanForm.get('heading').invalid && (subscriptionPlanForm.get('heading').dirty || subscriptionPlanForm.get('heading').touched)">Please
                                    enter heading</mat-error> -->
                                </td>
                                <td><div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Description"
                                        formControlName="description" required>
                                </div>
                                <!-- <mat-error
                                    *ngIf="subscriptionPlanForm.get('description').invalid && (subscriptionPlanForm.get('description').dirty || subscriptionPlanForm.get('description').touched)">Please
                                    enter description</mat-error> -->
                                </td>                               
                            </tr>
                            <tr *ngFor="let plans of planFeatures; let i=index">
                                <td><div class="input-group">
                                    <span class="index-text">{{i+1}}.</span>
                                    <input type="text" id="perks" class="form-control" placeholder="Enter Heading"
                                        value="{{plans.perks}}" (change)="editPlanDescription($event,i)" >
                                </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                    <input type="text" id="description" class="form-control" placeholder="Enter Description"
                                        value="{{plans.description}}" (change)="editPlanDescription($event,i)">
                                        <button (click)="clearDescription(i)">Delete</button>
                                </div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <div class="btn-perks">
                        <button type="button" class="btn btn-block btn-success" (click)="addDescription()">ADD PERKS</button>
                    </div>
                    </div>
                <label>Plan Active<span class="required"> * </span></label>
                <div class="form-group">
                    <select formControlName="active" class="form-control" style="color: black; width:20%;">
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>

                </div>
                <!-- <div class="form-group mt">
                    <label for="perks">Perks</label>
                    <div formArrayName="perks">
                        <div *ngFor="let perk of perkControls; let i = index" [formGroupName]="i">
                            <div class="perks">
                                <input formControlName="perk" class="form-controlsp mb12" type="text"
                                    placeholder="Enter perks" required>
                                <button *ngIf="!isOnlyPerk(i)" (click)="deleteModelDiallog(i)"
                                    class="btn btn-danger cross">x</button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="butad-prk">
                    <button class="btn btn-block login-btn mt-3" id="login" (click)="addPerk(null)">Add Perk</button>
                </div> -->
            </div>
            <div class="form-group formups">
            </div>
            <div class="set_size">
                <div class="row mt-3">
                    <div class="col-sm-4">
                        <p>
                            <input name="login" id="login" class="btn btn-block login-btn" type="button"
                                routerLink="/subscription-plans" value="Back">
                            <input name="login" id="login" class="ml-auto btn btn-block login-btn" (click)="onSubmit()"
                                type="submit" value="Save">
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <p>

                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" *ngIf="showDialog == 1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <form>
                        <div>
                            <div class="text-center">
                                <h2>Are you sure, you want to delete?</h2>
                            </div>

                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-default smingl_button" (click)="closeModalDialog()"
                            style="border-color: #117a8b;">Cancel</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="text-right">
                            <input type="submit" class="btn btn-danger btn-lg smingl_button" value="Delete"
                                (click)="removePerkFromDB(deletePerkVal,deletePerkIndex)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>