<div class="right_col screen-height v_cm edit-page justify-content-center" role="main">
    <div class="col-md-12 amenity-size login-wrapper justify-content: center;">
        <div class="text-left">
            <h3 class="mb-4"><a routerLink="/subscription-plans"><i class="fa fa-arrow-left mr-2"></i></a>Create
                Subscription plan</h3>
        </div>
        <form [formGroup]="subscriptionPlanForm">
            <div class="form-group formups">
                <div class="form-group">
                    <label for="name">Plan Name<span class="required"> * </span></label>
                    <input type="text" class="form-control" placeholder="Enter Plan Name" formControlName="planName"
                        required>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planName').invalid && (subscriptionPlanForm.get('planName').dirty || subscriptionPlanForm.get('planName').touched)">Please
                        enter a plan name</mat-error>
                </div>
                <div class="form-group">
                    <label>Plan Id<span class="required"> * </span></label>
                    <input type="text" class="form-control" placeholder="Enter Plan Id" formControlName="planId"
                        required>
                    <mat-error
                        *ngIf="subscriptionPlanForm.get('planId').invalid && (subscriptionPlanForm.get('planId').dirty || subscriptionPlanForm.get('planId').touched)">Please
                        enter a plan id</mat-error>
                </div>
                <label for="name">Plan Schedule<span class="required"> * </span></label>
                <div class="table-responsive d-flex mb-2">
                <table class="plain_table">
                    <thead>
                        <tr>
                            <th class="th-heading">Plan Price</th>
                            <th class="th-heading">Plan Validity</th>
                            <th class="th-heading">Plan Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input type="number" class="form-control" placeholder="Enter Plan Price"
                                    formControlName="planPrice" style="user-select: all;" required>
                            </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <div class="input-group-formups">
                                        <select formControlName="planValidity" selectedIndex=0 class="form-control dropDown" style="width: 215%;">
                                            <option *ngFor="let prd of Periods" [value]="prd.name">{{prd.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                                <td><div class="input-group">
                                    <div class="input-group-prepend">
                                    </div>
                                    <input type="text" class="form-control" placeholder="Enter Plan Id"
                                        formControlName="eachPlanId" required>
                                </div>                         
                        </tr>
                        <tr *ngFor="let plans of planSchedule; let i=index">
                            <td><div class="input-group">
                                <span class="index-text">{{i+1}}.</span>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input type="number" id="plan-price" class="form-control" 
                                    value="{{plans.planPrice}}" readonly>
                            </div>
                            <td><div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Period</span>
                                </div>
                                <input type="text" id="plan-validity" class="form-control" 
                                value="{{plans.planValidity}}" readonly>
                                
                            </div>
                             </td>
                                <td><div class="input-group">
                                    <div class="input-group-prepend">
                                    </div>
                                    <input type="text" id="eachPlanId" class="form-control" 
                                    value="{{plans.planId}}" readonly>
                                    <button (click)="clearPlan(i)">Delete</button>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="btn-perks">
                    <button type="button" class="btn btn-outline-info user-glyf" (click)="addPlan()">ADD</button>
                </div>
                </div>

                <label for="name">Plan Features<span class="required"> * </span></label>
                <div class="table-responsive d-flex mb-2">
                    <table class="plain_table">
                        <thead>
                            <tr>
                                <th class="th-heading">Perks</th>
                                <th class="th-heading">Description</th>
                                <th class="th-heading">Images</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Perks"
                                        formControlName="perks" required>
                                </div>
                                </td>
                                <td><div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Description"
                                        formControlName="description" required>
                                </div>
                                </td>    
                                <td><div class="input-group">
                                    <input type="file" class="form-control" placeholder="Upload Image" (change)="uploadImage($event)"
                                        formControlName="image" required>
                                </div>
                                </td>                           
                            </tr>
                            <tr *ngFor="let plans of planFeatures; let i=index">
                                <td><div class="input-group">
                                    <span class="index-text">{{i+1}}.</span>
                                    <input type="text" id="perks" class="form-control" 
                                        value="{{plans.perks}}" readonly>
                                </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                    <input type="text" id="description" class="form-control" 
                                        value="{{plans.description}}" readonly>
                                        
                                </div>
                                </td>
                                <td>
                                    <div class="input-group">
                                    <input type="text" id="image" class="form-control" 
                                       value="{{plans.image}}"  readonly>
                                        <button (click)="clearDescription(i)">Delete</button>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="btn-perks">
                        <button type="button" class="btn btn-outline-info user-glyf" (click)="addDescription()">ADD</button>
                    </div>
                    </div>

                <label>Plan Active<span class="required"> * </span></label>
                <div class="form-group">
                    <select formControlName="active" selectedIndex=0 class="form-control"
                        style="color: black; width:20%;">
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>

                </div>
            </div>
            <div class="form-group formups">
            </div>
            <div class="set_size">
                <div class="row mt-3">
                    <div class="col-sm-4">
                        <p>
                            <input name="login" id="login" class="btn btn-block login-btn" type="button"
                                routerLink="/subscription-plans" value="Back">
                            <input name="login" id="login" class="ml-auto btn btn-block login-btn" (click)="onSubmit()"
                                type="submit" value="Submit">
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <p>

                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-4"></div>
</div>