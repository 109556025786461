export class CodeConstants {
    public static readonly HOBBIES: string = 'Hobbies';
    public static readonly ORIENTATIONS: string = 'Sexual Orientaions';
    public static readonly PETS: string = 'Pets';
    public static readonly EDUCATIONS: string = 'Educations';
    public static readonly RELIGIONS: string = 'Religions';
}

export const REVENUE_SUBSCRIBER_NAME: any = {
    'gold': 'goldSubscribe',
    'popular': 'popularSubscribe',
    'month': 'monthlySubscribe'
}

export const AVAILABLE_PLANS:any=['All','PremiumPlans','PlusPlans','BoostPlans','SuperLikesPlans', 'SeeWhoLikeYouPlans', 'Boost24HrsPlan' ]

export const SEXUAL_INTEREST: any = [
    { value: 1, name: 'Men' },
    { value: 2, name: 'Women' },
    { value: 3, name: 'Everyone' }
]

export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

