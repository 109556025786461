import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-subscription-plan-details',
  templateUrl: './subscription-plan-details.component.html',
  styleUrls: ['./subscription-plan-details.component.scss']
})

// This component is for gettingsubscription plan details
export class SubscriptionPlanDetailsComponent implements OnInit {
  public subscriptionData: any;
  public startDate: any;
  public endDate: any;
  public rows = [];
  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

   // This function is called when page loads
  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    this.getUser(id)
  }

  // Function to get subscription plan details
  getUser(id) {
    this.authenticationService.detailsOfSubscriptionPlans(id).subscribe(res => {
      this.subscriptionData = res.data;
      console.log("30000",this.subscriptionData);
      this.rows = res.data.offersPlan;
    })
  }

  dateFormat(date){
    return date.split('T')[0]
  }

}
