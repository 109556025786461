import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setItemInLocalStorage(key, value): void {
    localStorage.setItem(key, value);
  }

  loadSessionData() {
    var sessionStr = JSON.parse(localStorage.getItem('admin'));
    if (sessionStr) {
      return sessionStr;
    }
    else {
      var sessionString = JSON.parse(localStorage.getItem('user'));
      return sessionString;
    }
  }
}
