import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public rows = [];
  public columns = [];
  page: Number;
  display = 'none';
  public searchKey = '';

  constructor(private authenticationService: AuthenticationService, private router: Router,) { }

  ngOnInit(): void {
    this.loadInit()
  }

  loadInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: false,
      searching: true,
      ordering: true,
      responsive: true,
      'columnDefs': [ {
        'targets': [3], /* column index */
        'orderable': false, /* true or false */
     }],
      ajax: (dataTablesParameters: any, callback) => {
        let localSearchKeyData = localStorage.getItem("searchKey");
        this.searchKey = dataTablesParameters.search.value;
        dataTablesParameters.search.value = localSearchKeyData? localSearchKeyData: (this.searchKey? this.searchKey: '');
        this.authenticationService.getSubscriberList(dataTablesParameters).subscribe(
          (resp: any) => {
            if (resp) {
              this.rows = resp.data;
              localStorage.removeItem('searchKey');
              callback({
                recordsTotal: resp.total,
                recordsFiltered: resp.total,
                data: []
              })
            }
          }, err => { 
            console.log('Error', err)
          }
        );
      }
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getSubscriberDetailPage(subscribeId){
    localStorage.setItem('searchKey', this.searchKey)
    this.router.navigateByUrl('/subscriber-details/'+ subscribeId);
  }

}
