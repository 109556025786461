<div class="right_col screen-height v_scrnss edit-page" role="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 mb-4 ">
                <h1 class="text-left font-weight-bold text-uppercase text-dark">Users</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="mt-3 ml-4">
                        <h2 style="display: inline;" class=" mt-8 mr-2">{{subscribeLabel}}</h2>
                        <label class="switch mb-2">
                            <input type="checkbox" (click)="checked($event)" [checked]="checkedSubscribed">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptions" class="table row-border hover mat-table" width="100%"
                            cellspacing="0" id="user-table">
                            <thead>
                                <tr>
                                    <th style="display: none;" class="th-heading"></th>
                                    <th class="th-heading">Name</th>
                                    <th class="th-heading">User ID</th>
                                    <th class="th-heading">Email</th>
                                    <th class="th-heading">Phone No.</th>
                                    <th class="th-heading">Account Created On</th>
                                    <th class="th-heading">Activation Status</th>
                                    <th class="th-heading">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="rows?.length != 0">
                                <tr *ngFor="let user of rows">
                                    <td style="display: none;"></td>
                                    <td>{{user.name}}</td>
                                    <td>{{user._id}}</td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.phone}}</td>
                                    <td>{{user.createdDate | date}}</td>

                                    <td>
                                        <label class="switch">
                                            <input type="checkbox" [checked]="user.userStatus == true"
                                                (click)="changeStatus(user._id, $event)">
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td><span class="flex_wrp flex_strt drec_colomn">
                                            <a> <button class="btn btn-outline-success user-glyf"
                                                    (click)="getUsersEditPage(user._id)">Edit</button></a>
                                            <a> <button class="btn btn-outline-info user-glyf"
                                                    (click)="getUsersDetailPage(user._id)">Details</button></a>
                                            <button class="btn btn-outline-danger user-glyf"
                                                (click)="deleteModelDiallog(user._id)">Remove</button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="rows?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data available in table!</td>
                                </tr>
                            <tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" *ngIf="showDialog == 1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form>
                            <div>
                                <div class="text-center">
                                    <h2>Are you sure, you want to delete?</h2>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-default smingl_button" (click)="closeModalDialog()"
                                style="border-color: #117a8b;">Cancel</button>
                        </div>
                        <div class="col-sm-6">
                            <div class="text-right">
                                <input type="submit" class="btn btn-danger btn-lg smingl_button" value="Delete"
                                    (click)="deleteUser(deleteId)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>