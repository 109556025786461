import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { FEATURES, SCHEDULE } from 'src/app/interfaces/interface';

@Component({
  selector: 'app-edit-subscription-plans',
  templateUrl: './edit-subscription-plans.component.html',
  styleUrls: ['./edit-subscription-plans.component.scss']
})
export class EditSubscriptionPlansComponent implements OnInit {
  public subscriptionPlanForm: FormGroup;
  public subscriptionPayload: any;
  private id: any;
  public loggedInAdmin: any;
  public subscriptionPlanData: any;
  public perks: any = [];
  public showDialog: number = 0;
  public deletePerkVal: any;
  public display: string;
  public planSchedule: Array<SCHEDULE>;
  public planFeatures: Array<FEATURES>;
  public deletePerkIndex: number;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.fetchSubscriptionPlanDataById();
    }
    this.loggedInAdmin = JSON.parse(localStorage.getItem('admin'));

    this.subscriptionPlanForm = this.fb.group({
      planName: [null, Validators.required],
      planId: [null, Validators.required],
      planPrice:[null, Validators.required],
      planValidity:[null, Validators.required],
      perks:[null, Validators.required],
      description:[null, Validators.required],
      active: [null, Validators.required],
    });
  }


  createPerkFormGroup(perk: any): FormGroup {
    return this.fb.group({
      perk: [perk, Validators.required]
    });
  }

  addPerk(perk: string): void {
    const perks = this.subscriptionPlanForm.get('perks') as FormArray;
    let EmptyExist = perks.value.some(function (data) {
      return (!data.perk);
    })
    if (!EmptyExist) {
      perks.push(this.createPerkFormGroup(perk));
    }
  }

  deleteModelDiallog(index: number) {
    const perks = this.subscriptionPlanData.perks;
    if (perks[index]) {
      this.deletePerkVal = perks[index];
      this.display = 'block'; //Set block css
      this.deletePerkIndex = index;
      this.showDialog = 1
    } else {
      this.removePerk(index);
    }
  }

  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
  }

  removePerk(index: number) {
    const perks = this.subscriptionPlanForm.get('perks') as FormArray;
    if (perks.length === 1) {
      return;
    }
    perks.removeAt(index);
  }

  removePerkFromDB(perk: any, index: number) {
    const perks = this.subscriptionPlanData.perks;
    this.display = 'none';
    const containsPerk = perks.some(value => value === perk);
    if (containsPerk) {
      this.authenticationService.deletePerkOfSubscriptionPlans(this.id, perk).subscribe((res => {
        if (res) {
          this.removePerk(index)
          this.toastr.success("Subscription plan perk deleted successfully.");
          this.authenticationService.detailsOfSubscriptionPlans(this.id).subscribe((res: any) => {
            this.subscriptionPlanData = res.data;
          });
        } else {
          this.toastr.error("Error while updating subscription plan.");
        }
      }))
    }
  }

  get perkControls() {
    return (this.subscriptionPlanForm.get('perks') as FormArray).controls;
  }

  isOnlyPerk(index: number): boolean {
    if (index === 0) {
      return true;
    }
    if (this.perkControls.length === 1 && index === 0) {
      return true;
    }
    if (this.perkControls.length === 2 && !this.perkControls[index].value.perk) {
      return true;
    }
    if (index = 0 && this.perkControls.length === 2 && !this.perkControls[1].value.perk) {
      return true;
    }
    return false;
  }

  onSubmit(): void {

     this.subscriptionPayload = {
      "planName": this.subscriptionPlanForm.controls.planName.value,
      "planId": this.subscriptionPlanForm.controls.planId.value,
      "planSchedule": this.planSchedule,
      "planFeatures":this.planFeatures,
      "active": this.subscriptionPlanForm.controls.active.value
    }

    this.authenticationService.updateSubscriptionPlan(this.subscriptionPayload, this.id).subscribe((res: any) => {
      if (res) {
        this.toastr.success("Subscription plan updated successfully.");
        this.subscriptionPlanData = res.data;
      } else {
        this.toastr.error("Error while updating subscription plan.");
      }},
      (error)=>{
        this.toastr.error(error.error.message);
      }
    )
  }

  fetchSubscriptionPlanDataById() {
    if (this.id) {
      this.authenticationService.detailsOfSubscriptionPlans(this.id).subscribe((res: any) => {
        this.subscriptionPlanData = res.data;
        if (this.subscriptionPlanData) {
          this.subscriptionPlanForm.patchValue(this.subscriptionPlanData);
          this.planSchedule= this.subscriptionPlanData.planSchedule;
          this.planFeatures= this.subscriptionPlanData.planFeatures;
        }
      })
    } else {
      this.toastr.error("Error while fetching subscription plan");
    }
  }


  public editPlanSchedule(event:any,i:number){
     const id = event.target.id;
     const value = Number(event.target.value);
      if(id === `plan-price${i}`){
          this.planSchedule[i].planPrice = value;
      }
      else if(id === `plan-validity${i}`){
        if(!this.isPlanExist(value)){
          this.planSchedule[i].planValidity = value;
        }
        else{
          (<HTMLInputElement>document.getElementById(`${id}`)).value = String(this.planSchedule[i].planValidity);
        }
      };
   }

   public editPlanDescription(event:any,i:number){
    const id = event.target.id;
    const value = String(event.target.value);
     if(id === 'perks'){
         this.planFeatures[i].perks = value;
     }
     else if(id === 'description'){
      this.planFeatures[i].description = value;
     };

  } 
  
   public addPlan(){
    const plans = {
      planPrice: this.subscriptionPlanForm.controls.planPrice.value ,
      planValidity: this.subscriptionPlanForm.controls.planValidity.value,
      planId: this.subscriptionPlanForm.controls.planId.value
    }
    if(!this.subscriptionPlanForm.controls.planPrice.value || !this.subscriptionPlanForm.controls.planValidity.value ){
      return this.toastr.error('Please add the required Plan Schedule fields');
    }
    if(!this.isPlanExist(plans.planValidity)){
    this.planSchedule.push(plans);
    this.subscriptionPlanForm.controls.planPrice.setValue('');
    this.subscriptionPlanForm.controls.planValidity.setValue('');
    }
  }  

  public addDescription(){
    const description = {
      perks: this.subscriptionPlanForm.controls.heading.value ,
      description: this.subscriptionPlanForm.controls.description.value,
      image: ''
    }
    if(!this.subscriptionPlanForm.controls.heading.value || !this.subscriptionPlanForm.controls.description.value ){
      return this.toastr.error('Please add the required Plan Description fields');
    }
    this.planFeatures.push(description);
    this.subscriptionPlanForm.controls.heading.setValue('');
    this.subscriptionPlanForm.controls.description.setValue('');
  } 

  public isPlanExist(validity: number){
    for(const plans of this.planSchedule){
      if(plans.planValidity === validity){
        this.toastr.error('Plan Validity already exists');
        return true;
      }
    }
  }

  public clearPlan(index: number){
    if(this.planSchedule.length != 1){
    this.planSchedule.splice(index,1);
    }
    else{
      return this.toastr.error('Atleast One Plan Schedule is Required');
    }
 }

 public clearDescription(index: number){
   if(this.planFeatures.length != 1){
    this.planFeatures.splice(index,1);
   }
   else{
    return this.toastr.error('Atleast One Plan Description is Required');
   }
}

}