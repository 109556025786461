<div class="right_col screen-height v_scrnss v_cm edit-page" role="main">
    <div class="col-md-12 amenity-size login-wrapper">
        <div class="text-left">
            <h3 class="mb-4"><a routerLink="/manage-content"><i class="fa fa-arrow-left mr-2"></i></a>{{contentName}} Details</h3>
        </div>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="th-heading">ID</th>
                        <th class="th-heading">Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let option of options">
                        <td>{{ option.id + 1 }}</td>
                        <td>{{ option.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>