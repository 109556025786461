import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CodeConstants } from 'src/app/constants/code-constants';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-manage-content',
  templateUrl: './manage-content.component.html',
  styleUrls: ['./manage-content.component.scss']
})
export class ManageContentComponent implements OnInit {
  public rows: Array<object> = [];
  public display: string = 'none';
  public totalHobbies: number = 0;
  public totalOrientations: number = 0;
  public totalpets: number = 0;
  private codeConstants=CodeConstants;
  public totalEducation: number=0;
  public totalReligion: number=0;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getHobbies();
    this.getOrientations();
    this.getEducations();
    this.getReligions();
  }

  getHobbies() {
    this.authenticationService.getHobbies().subscribe((res: any) => {
      if (res.hobbies && res.hobbies.length > 0) {
        this.totalHobbies = res.hobbies.length;
        this.rows.push({ name: this.codeConstants.HOBBIES, length: this.totalHobbies });
      }
    })
  }

  getOrientations() {
    this.authenticationService.getOrientations().subscribe((res: any) => {
      if (res.sexOrientation && res.sexOrientation.length > 0) {
        this.totalOrientations = res.sexOrientation.length;
        this.rows.push({ name: this.codeConstants.ORIENTATIONS, length: this.totalOrientations });
      }
    });
  };

  getEducations() {
    this.authenticationService.getEducations().subscribe((res: any) => {
      if (res.education && res.education.length > 0) {
        this.totalEducation = res.education.length;
        this.rows.push({ name: this.codeConstants.EDUCATIONS, length: this.totalEducation });
      }
    });
  }

  getReligions() {
    this.authenticationService.getReligions().subscribe((res: any) => {
      if (res.religion && res.religion.length > 0) {
        this.totalReligion = res.religion.length;
        this.rows.push({ name: this.codeConstants.RELIGIONS, length: this.totalReligion });
      }
    });
  }

}
