<div class="right_col screen-height v_cm edit-page justify-content-center" role="main">
    <div class="col-md-12 login-wrapper amenity-size justify-content: center;" *ngIf="userData">
        <div class="text-left">
            <h3 class="mb-4"><a [routerLink]="['/users']"><i class="fa fa-arrow-left mr-2" routeLink="/users"></i></a>Edit user</h3>
        </div>
        <form [formGroup]="editUserForm">
            <div class="form-group formups">


                   <div class="form-group formups">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" placeholder="Enter Name" formControlName="name">
                    </div>
                  
                    <div class="form-group formups">
                      <label for="email">Email</label>
                      <input type="text" class="form-control" placeholder="Enter Email" formControlName="email">
                    </div>
                  
                    <div class="form-group formups">
                      <label for="phone">Contact Number</label>
                      <input type="number" class="form-control" placeholder="Enter Contact Number" formControlName="phone">
                    </div>
                    <div class="form-group formups">
                      <label for="birthday">Birthday</label>
                      <input type="date" class="form-control" formControlName="birthDate">
                    </div>
                    
                    <div class="form-group formups">
                        <label for="sexualOrientation">Sexual Orientation</label> 
                        <select formControlName="sexualOrientation" class="form-control" multiple>
                        <option *ngFor="let orientation of Sexorientations" [value]="orientation.id">{{orientation.name}}</option>
                        </select>
                    </div>

                    <div class="form-group formups">
                        <label for="sexualInterest">Sexual Interest</label> 
                        <select formControlName="sexualInterest" class="form-control">
                            <option value="default" disabled>Select an option</option>
                            <option *ngFor="let orientation of sexInterest" [value]="orientation.value">{{orientation.name}}</option>
                        </select>                    
                    </div>
                    
                    <div class="form-group formups">
                        <label for="interest">Interest</label> 
                        <select formControlName="interest" class="form-control" multiple>
                            <option *ngFor="let interests of Hobbies" [value]="interests.id">{{interests.name}}</option>
                        </select>                    
                    </div>

                    <div class="form-group formups">
                        <label for="education">Education</label> 
                        <select formControlName="education" class="form-control">
                            <option *ngFor="let education of educations" [value]="education.id">{{education.name}}</option>
                        </select>
                    </div>
                    <div class="form-group formups">
                        <label for="religion">Religion</label> 
                        <select formControlName="religion" class="form-control">
                            <option *ngFor="let religion of religions" [value]="religion.id">{{religion.name}}</option>
                        </select>
                    </div>
                    <div class="form-group formups">
                        <label for="photoUrl">Photo URL</label> 
                        <input type="text" class="form-control" placeholder="Enter Photo URL" formControlName="photoUrl">
                    </div>
                    
                    <div class="form-group formups">
                        <label for="age">Age</label> 
                        <input type="text" class="form-control" placeholder="Enter Age" formControlName="age">
                    </div>
                    <div class="form-group formups">
                        <label for="height">Height</label> 
                        <input type="number" class="form-control" placeholder="Enter Height in CM" formControlName="height">
                    </div>
                    
                    <div class="form-group formups">
                        <label for="maxDistance">Max Distance</label> 
                        <input type="number" class="form-control" placeholder="Enter Max Distance" formControlName="maxDistance">
                    </div>
                    
                    <div class="form-group formups">
                        <label for="ageRange">Age Range</label>
                        <div id="ageRange">
                            <input type="number" class="form-control-small form-control" placeholder="Enter Min Age" formControlName="minAgeRange" style="margin-right: 4em;">
                            <input type="number" class="form-control-small form-control" placeholder="Enter Max Age" formControlName="maxAgeRange">
                        </div>
                    </div>

                    <div class="form-group formups">
                        <label for="blocked">Blocked Users</label> 
                        <input type="text" class="form-control" placeholder="Add Blocked Users" formControlName="blocked">
                    </div>
                    
                    <div class="form-group formups">
                        <label for="likedUsers">Liked Users</label> 
                        <input type="text" class="form-control" placeholder="Add Liked Users" formControlName="likedUsers">
                    </div>

                    <div class="form-group formups">
                        <label for="likedBy">Liked By Users</label> 
                        <input type="text" class="form-control" placeholder="Add Liked By Users" formControlName="likedBy">
                    </div>


                    <div class="form-group formups">
                        <label for="aboutMe">About Me</label> 
                        <input type="text" class="form-control" placeholder="Add About Me" formControlName="aboutMe">
                    </div>

                    <div class="form-group formups">
                        <label for="gender">Gender</label> 
                        <select formControlName="gender" class="form-control">
                            <option value="default" disabled>Select an option</option>
                            <option value="man">Man</option>
                            <option value="woman">Woman</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div class="form-group formups">
                        <label for="drinking">Drinking</label> 
                        <select formControlName="drinking" class="form-control">
                            <option value="default" disabled>Select an option</option>
                            <option value="Socially">Socially</option>
                            <option value="Never">Never</option>
                        </select>
                    </div>

                    <div class="form-group formups">
                        <label for="smoking">Smoking</label> 
                        <select formControlName="smoking" class="form-control">
                            <option value="default" disabled>Select an option</option>
                            <option value="Socially">Socially</option>
                            <option value="Never">Never</option>
                            <option value="Regularly">Regularly</option>
                        </select>
                    </div>

                    <div class="form-group formups">
                        <label for="workout">Workout</label> 
                        <select formControlName="workout" class="form-control">
                            <option value="default" disabled>Select an option</option>
                            <option value="Everyday">Everyday</option>
                            <option value="Often">Often</option>
                            <option value="Sometimes">Sometimes</option>
                        </select>
                    </div>

                   

                    <div class="form-group formups">
                        <label for="sexualOrientation">Account Created Date</label> 
                        <input type="text" class="form-control" formControlName="createdDate">
                    </div>

                    <div class="col-sm-6">
                        <p style="margin-top: 20px;">
                            <input name="login" id="login" class="btn btn-block login-btn" type="submit" value="Save" (click)="onSubmit()">
                        </p>
                    </div>
            </div>
        </form>
    </div>
    <div class="col-md-4"></div>
</div>