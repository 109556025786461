<div class="right_col screen-height u_scrns edit-page" role="main">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" id='mag-profile'>

            <div class="card  mb-4">
                <h3 class="m-0 font-weight-bold profile-title"><a [routerLink]="['/subscriber-list']"><i
                            class="fa fa-arrow-left mr-2"></i></a>Subscriber Details</h3>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card-body">
                            <table id="tmtable" class="display " style="width:100%" class="table row-border hover"
                                width="100%" cellspacing="0" id="user-table" *ngIf="subscriber">
                                <tbody>
                                    <tr>
                                        <th width="15%">First Name</th>
                                        <td id="tabledeatils">{{subscriber.user.firstName}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th width="25%">Last Name</th>
                                        <td id="tabledeatils">{{(subscriber && subscriber.user && subscriber.user.lastName)? subscriber.user.lastName: 'Not available'}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>Email</th>
                                        <td>{{subscriber.user.email}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>Contact Number</th>
                                        <td>{{(subscriber && subscriber.user && subscriber.user.mobileNumber)? subscriber.user.mobileNumber: 'Not available'}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>Subscribed plan</th>
                                        <td>{{subscriber.planCharge}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>Plan Validity</th>
                                        <td>{{subscriber.planDetails.planValidity}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>Special offer</th>
                                        <td>{{(subscriber && subscriber.planDetails && subscriber.planDetails.isSpecial)? 'Yes': 'No'}}</td>
                                    </tr>
                                </tbody>
                                <!-- <tbody>
                                    <tr>
                                        <th>Perks</th>
                                        <td *ngIf="subscriber && subscriber.perks.length === 0; else perksElse">
                                            Not Available</td>
                                        <ng-template #perksElse>
                                            <td>
                                                <li *ngFor="let item of subscriber.perks; index as i; trackBy: trackByFn">
                                                    {{subscriber.perks[i]}}</li>
                                            </td>
                                        </ng-template>
                                    </tr>
                                </tbody> -->
                            </table>
                        </div>

                    </div>
                </div>
                <div class="page-title">
                    <div class="title_left">
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>

        </div>
    </div>
</div>