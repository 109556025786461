import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public rows = [];
  public columns = [];
  page: Number;
  deleteId: any;
  display = 'none';
  zIndex: any;
  showDialog: any;
  public searchKey = '';
  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadInit();
  }

  loadInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: false,
      searching: true,
      ordering: true,
      responsive: true,
      'columnDefs': [{
        'targets': [1, 2, 3], /* column index */
        'orderable': false, /* true or false */
      }],
      ajax: (dataTablesParameters: any) => {
        let localSearchKeyData = localStorage.getItem("searchKey");
        this.searchKey = dataTablesParameters.search.value;
        dataTablesParameters.search.value = localSearchKeyData ? localSearchKeyData : (this.searchKey ? this.searchKey : '');
        this.authenticationService.subscriptionPlansList(dataTablesParameters).subscribe(
          (resp: any) => {
            if (resp) {
              this.rows = resp.data;
              localStorage.removeItem('searchKey');
            }
          }, err => {
            console.log('Error', err)
          }
        );
      }
    };
  }

  deleteSubscriptionPlans(id) {
    const planId = id;
    this.display = 'none';
    this.authenticationService.deleteSubscriptionPlans(planId).subscribe((res) => {
      $("#user-table").DataTable().draw();
    })
  }

  getSubscriptionId(id) {
    this.router.navigateByUrl('/update-subscription/' + id);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  deleteModelDialog(deleteId) {
    this.deleteId = deleteId;
    this.display = 'block'; //Set block css
    this.zIndex = '0';
    this.showDialog = 1
  }

  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
  }

  getSubscriptionPlansDetailPage(subscriptionPlanId) {
    localStorage.setItem('searchKey', this.searchKey)
    this.router.navigateByUrl('/subscription-plans/' + subscriptionPlanId);
  }

}
