import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/core/header/header.component';
import { SidebarComponent } from './components/core/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataTablesModule } from 'angular-datatables';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UsersComponent } from './components/users/users.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AuthGuard } from './guards/auth.guards';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SubscriptionPlansComponent } from './components/subscription-plans/subscription-plans.component';
import { SubscriptionPlanDetailsComponent } from './components/subscription-plan-details/subscription-plan-details.component';
import { CreateSubscriptionPlansComponent } from './components/create-subscription-plans/create-subscription-plans.component';
import { EditSubscriptionPlansComponent } from './components/edit-subscription-plans/edit-subscription-plans.component';
import { SubscriberComponent } from './components/subscriber/subscriber.component';
import { SubscriberDetailsComponent } from './components/subscriber-details/subscriber-details.component';
import {MatSelectModule} from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxMaskModule} from 'ngx-mask';
import { ManageContentComponent } from './components/manage-content/manage-content.component';
import { EditContentComponent } from './components/edit-content/edit-content.component';
import { ContentDetailsComponent } from './components/content-details/content-details.component';
// import { NgxSpinnerModule } from 'ngx-spinner';
import {  RevenueComponent } from "./components/revenue/revenue.component";
import { SubscriptionReportComponent } from './components/subscription-report/subscription-report.component';
import { VerifyRealTimeImageComponent } from './components/verify-real-time-image/verify-real-time-image.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    UserProfileComponent,
    UsersComponent,
    ChangePasswordComponent,
    EditUserComponent,
    SubscriptionPlansComponent,
    SubscriptionPlanDetailsComponent,
    CreateSubscriptionPlansComponent,
    EditSubscriptionPlansComponent,
    SubscriberComponent,
    SubscriberDetailsComponent,
    ManageContentComponent,
    EditContentComponent,
    ContentDetailsComponent,
    RevenueComponent,
    SubscriptionReportComponent,
    VerifyRealTimeImageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    AngularEditorModule,
    MatSelectModule,
    NgSelectModule,
    MatProgressBarModule,
    // NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
