<div class="top_nav sticky-header sticky-header-media">
	<div class="nav_menu ">
        <nav class="navbar navbar-expand-sm bg-info navbar-light navbarMedia">
            <button type="button" class="none" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"  (click)="showNav()" >
                <i class="fa fa-align-justify" aria-hidden="true"></i>
            </button>
            <!-- <ul  class="nav navbar-nav  ml-auto ">
            <li class="nav-item dropdown no-arrow">
                <a class="glyphicon glyphicon-user glyphicon-user brown" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small"></span>
                </a>
                Dropdown - User Information
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown" style="left: -110px;">
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"   routerLink="/change-password">
                        <i class="glyphicon glyphicon-cog text-gray-400" aria-hidden="true"></i>
                    Change Password
                    </a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"(click)="logout()" routerLink="/login" >
                        <i class="fa fa-sign-out text-gray-400" aria-hidden="true"></i>
                    Logout
                    </a>
                </div>
                </li>
            </ul> -->
            <ul  class="nav navbar-nav  ml-auto">
                <li class="nav-item dropdown no-arrow">
                  <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <!-- <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{data.firstName | titlecase}}  {{data.lastName | titlecase}}</span> -->
                  <img class="img-profile rounded-circle" src="assets/image/profile.jpg">
                  </a>
                  <!-- Dropdown - User Information -->
                  <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown" style="margin-left: -90px;">
                  <!-- <a class="dropdown-item" >
                  <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                  </a> -->
                  <a class="dropdown-item" (click)="changePassword()" >
                  <i class="fa fa-cogs fa-sm fa-fw mr-2 text-gray-400"  ></i>
                  Change Password
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" (click)="logout()">
                  <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                  </a>
                  </div>
                  </li>
              </ul>
        </nav>
    </div>
</div>
