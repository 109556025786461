import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as Chart from 'chart.js';
import { REVENUE_SUBSCRIBER_NAME } from '../../constants/code-constants'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  public loggedInUser: any;
  public subscriptions: number = 0;
  public total_users: number = 0;
  public totalInactiveUsers: number = 0;
  public totalRevenue: number = 0;
  public goldPlanPrice: number = 0;
  public popularPlanPrice: number = 0;
  public standardPlanPrice: number = 0;
  public currentDate = new Date();
  public curmonth: string = (this.currentDate.getMonth() + 1).toString();
  public curyear: string = (this.currentDate.getFullYear()).toString();
  public chart;
  public premiumSubscribers: number = 0;
  public plusSubscribers: number = 0;
  public boostSubscribers: number = 0;
  public superLikeSubscribers: number = 0;
  public premiumPlanRevenue: number = 0;
  public plusPlanRevenue: number = 0;
  public boostPlanRevenue: number = 0;
  public superLikePlanRevenue: number = 0;
  public selectedOption: string = '';
  public selectedOptionforSub: string = '';

  public subscriptionPlanNames: Array<string>=[];

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.getDashboardData();
  }

  onOptionSelected(value: string) {
    this.selectedOption = value;
    this.createChart(this.premiumPlanRevenue, this.plusPlanRevenue, this.boostPlanRevenue,this.superLikePlanRevenue, value);
  }

  onOptionSelectedForSubs(value: string) {
    this.selectedOptionforSub = value;
    this.createChartForSubscription(this.premiumSubscribers, this.plusSubscribers, this.boostSubscribers,this.superLikeSubscribers, this.selectedOptionforSub);
  }

  getDashboardData() {
    

    this.authenticationService.dashBoardData().subscribe(res => {
      if (res.data) {
        this.total_users = res.data.totalUsers ? res.data.totalUsers : 0;
        this.subscriptions = res.data.totalSubscriptions[0].activeSubscribers ? res.data.totalSubscriptions[0].activeSubscribers : 0;
        this.totalInactiveUsers = res.data.totalInactiveUsers ? res.data.totalInactiveUsers : 0;
        this.premiumSubscribers = res.data.premiumSubscribers? res.data.premiumSubscribers:0;
        this.plusSubscribers=res.data.plusSubscribers?res.data.plusSubscribers:0;
        this.boostSubscribers= res.data.boostSubscribers?res.data.boostSubscribers:0;
        this.plusSubscribers=res.data.superLikeSubscribers?res.data.superLikeSubscribers:0;
        this.createChartForSubscription(this.premiumSubscribers, this.plusSubscribers, this.boostSubscribers,this.superLikeSubscribers, 'bar');
      }
    })

    this.authenticationService.getRevenueReport(this.curmonth, this.curyear).subscribe((response: any) => {
      this.totalRevenue = response.data.totalRevenue? response.data.totalRevenue: 0;
      this.premiumPlanRevenue=response.data.premiumPlanRevenue?response.data.premiumPlanRevenue:0;
      this.plusPlanRevenue=response.data.plusPlanRevenue?response.data.plusPlanRevenue:0;
      this.boostPlanRevenue=response.data.boostPlanRevenue?response.data.boostPlanRevenue:0;
      this.superLikePlanRevenue=response.data.superLikePlanRevenue?response.data.superLikePlanRevenue:0;
      this.createChart(this.premiumPlanRevenue, this.plusPlanRevenue, this.boostPlanRevenue,this.superLikePlanRevenue, 'bar');
      

    })
   

  }

  createChart(premiumPlanRevenue: any, plusPlanRevenue: any, boostPlanRevenue: any,superLikePlanRevenue:any, type: any): void {
    this.chart = new Chart('myChart', {
      type: type,
      data: {
        labels: ['Premium Plan','Plus Plan', 'Boost Plan', 'SuperLike Plan'],
        datasets: [{
          label: 'Revenue from Subscriptions',
          data: [premiumPlanRevenue,plusPlanRevenue,boostPlanRevenue,superLikePlanRevenue],
          backgroundColor: [
            'rgba(216, 146, 133, 0.8)',
            'rgba(168, 222, 160, 0.8)',
            'rgba(193, 201, 222, 0.8)',
            'rgba(216, 204, 149, 0.8)'
          ],
          borderColor: [
            'rgba(216, 146, 133, 0.8)',
            'rgba(168, 222, 160, 0.8)',
            'rgba(193, 201, 222, 0.8)',
            'rgba(216, 204, 149, 0.8)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        events: [],
        plugins: {
          tooltip: {
            events: ['click']
          }
        }
      }
    });
  }

  createChartForSubscription(premiumSubscribers: any, plusSubscribers: any, boostSubscribers: any, superLikeSubscribers: any, type: any): void {
    this.chart = new Chart('myChartForSubscription', {
      type: type,
      data: {
        labels: ['Premium Plan','Plus Plan', 'Boost Plan', 'SuperLike Plan'],
        datasets: [{
          label: 'Number of Subscriber',
          data: [premiumSubscribers, plusSubscribers, boostSubscribers, superLikeSubscribers],
          backgroundColor: [
            'rgba(216, 146, 133, 0.8)',
            'rgba(168, 222, 160, 0.8)',
            'rgba(193, 201, 222, 0.8)',
            'rgba(216, 204, 149, 0.8)'
          ],
          borderColor: [
            'rgba(216, 146, 133, 0.8)',
            'rgba(168, 222, 160, 0.8)',
            'rgba(193, 201, 222, 0.8)',
            'rgba(216, 204, 149, 0.8)'
          ],
          borderWidth: 1
        },
      ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        events: [],
        plugins: {
          tooltip: {
            events: ['click']
          }
        }
      }
    });
  }



}
