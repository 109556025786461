import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OPTION } from 'src/app/modals/option.module';
import { SEXUAL_INTEREST } from '../../constants/code-constants'
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public userData;
  editUserForm: FormGroup;
  id: any;
  Hobbies:OPTION[]=[];
  Sexorientations:OPTION[]=[];
  educations:OPTION[]=[];
  religions:OPTION[]=[];
  selectedSexOrientations: any=[];
  public sexInterest: any;
  
  constructor(private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.initFormData();
    this.getUser();
    this.getHobbies();
    this.getOrientations();
    this.getEducations();
    this.getReligions();
    this.sexInterest=SEXUAL_INTEREST;
  }
  
  getUser() {
    this.authenticationService.getSingleUser(this.id).subscribe((res) => {
      this.userData=res.user;
      if(this.userData){
        this.createForm();
      }
    })
  }

  getHobbies() {
    this.authenticationService.getHobbies().subscribe((res: any) => {
      if (res.hobbies && res.hobbies.length > 0) {
        this.Hobbies=res.hobbies
        .map(({name, hobbiesId}) => ({name, id:hobbiesId}));
      }
    })
  }

  getOrientations() {
    this.authenticationService.getOrientations().subscribe((res: any) => {
      if (res.sexOrientation && res.sexOrientation.length > 0) {
        this.Sexorientations=res.sexOrientation
        .map(({name, orientationId}) => ({name, id:orientationId}));
      }
    });
  };

  getEducations() {
    this.authenticationService.getEducations().subscribe((res: any) => {
      if (res.education && res.education.length > 0) {
        this.educations=res.education
        .map(({name, educationId}) => ({name, id:educationId}));
      }
    });
  };

  getReligions() {
    this.authenticationService.getReligions().subscribe((res: any) => {
      if (res.religion && res.religion.length > 0) {
        this.religions=res.religion
        .map(({name, religionId}) => ({name, id:religionId}));
      }
    });
  };

  createForm() {
    this.editUserForm = this.fb.group({
      name: [this.userData.name],
      email: [this.userData.email],
      phone: [this.userData.phone],
      birthDate: [this.userData.birthDate? new Date(Date.parse(this.userData.birthDate)).toISOString().substring(0, 10) : this.userData.birthDate],
      sexualOrientation: [this.userData.sexualOrientation],
      sexualInterest: [this.userData.sexualInterest? this.userData.sexualInterest: 'default'],
      interest: [this.userData.interest],
      education:[this.userData.education],
      religion:[this.userData.religion],
      photoUrl: [this.userData.images],
      age: [this.userData.age],
      height:[this.userData.height],
      maxDistance: [this.userData.maxDistance?.distance],
      minAgeRange: [this.userData.ageRange?.minAge],
      maxAgeRange: [this.userData.ageRange?.maxAge],
      blocked: [this.userData.blocked],
      likedUsers: [this.userData.likedUsers],
      likedBy: [this.userData.likedBy],
      aboutMe: [this.userData.aboutMe],
      gender: [this.userData.gender? this.userData.gender: 'default'],
      drinking: [this.userData.lifestyle.drinking? this.userData.lifestyle.drinking: 'default'],
      smoking: [this.userData.lifestyle.smoking? this.userData.lifestyle.smoking: 'default'],
      workout: [this.userData.lifestyle.workout? this.userData.lifestyle.workout: 'default'],
      sleepingHabbits: [this.userData.lifestyle.sleepingHabbits? this.userData.lifestyle.sleepingHabbits: 'default'],
      socialMedia: [this.userData.lifestyle.socialMedia? this.userData.lifestyle.socialMedia: 'default'],
      createdDate: [this.userData.createdDate.substring(0,10)]
    });
  }

  initFormData() {
    this.editUserForm = this.fb.group({
      name: ['',Validators.required],
      email: ['',[Validators.required, Validators.email]],
      phone: ['',Validators.required],
      birthDate: ['',Validators.required],
      sexualOrientation: [''],
      sexualInterest: [''],
      interest: [''],
      education:[''],
      religion:[''],
      photoUrl: [''],
      age: ['',Validators.required],
      height:[''],
      maxDistance: [''],
      minAgeRange: [''],
      maxAgeRange: [''],
      blocked: [''],
      likedUsers: [''],
      likedBy: [''],
      aboutMe: [''],
      gender: ['',Validators.required],
      drinking: [''],
      smoking: [''],
      workout: [''],
      sleepingHabbits: [''],
      socialMedia: [''],
      createdDate: ['',Validators.required]
    });
  }

  onSubmit(): void {
    let data:any;
    data= {};
    const formControls = this.editUserForm.controls;
    for (const controlName in formControls) {
      if (formControls.hasOwnProperty(controlName) && formControls[controlName].value && formControls[controlName].value!=='default') {
        data[controlName] = formControls[controlName].value;
      }
    }
    this.authenticationService.updateUser(this.id, data).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.toastr.success(res.message)
        }
      }, err => {
        this.toastr.error(err.message, 'Failure!', { progressBar: true });
      });
  }

  clearForm() {
    this.editUserForm.reset();
  }

}
