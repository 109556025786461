import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AVAILABLE_PLANS, monthNames } from '../../constants/code-constants'
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as Chart from 'chart.js';
import { REVENUE_SUBSCRIBER_NAME } from '../../constants/code-constants'
import { SUBSCRIBER } from 'src/app/interfaces/interface';

@Component({
  selector: 'app-subscription-report',
  templateUrl: './subscription-report.component.html',
  styleUrls: ['./subscription-report.component.scss']
})

export class SubscriptionReportComponent implements OnInit {
  public totalUser: number = 0;
  public subscriptionReport = {
    totalSubscribers: 0,
    totalPremiumPlanSubscribers:0,
    totalPlusPlanSubscribers:0,
    totalBoostPlanSubscribers:0,
    totalSuperLikePlanSubscribers:0,
    totalSeeWhoLikeYouPlanSubscribers:0,
    totalBoost24HrsPlanSubscribers:0
  }
  public chart: any;
  public currentDate = new Date();
  public curmonth: string = (this.currentDate.getMonth() + 1).toString();
  public curyear: any = (this.currentDate.getFullYear()).toString();
  public monthName: any = [];
  public years: any = [];
  public selectedOptionforRev: string='bar';
  public myChart: any;
  public showSubscribers:number;
  public dropdownPlanIds:Array<string>=AVAILABLE_PLANS;
  public eachPlanSubscribers: Array<SUBSCRIBER>=[];
  public subscribers: Array<SUBSCRIBER>=[];
  public subscriptionReportForm: FormGroup;
  public allSubscriptionPlanList:Array<Object>;

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    let newDate = new Date();
    this.curmonth = (newDate.getMonth() + 1).toString();

  }
  ngOnInit(): void {
    this.getUpdatedMonthYears();
    this.generateSubscriptionReportForm();
    this.authenticationService.dashBoardData().subscribe(res => {
      if (res.data) {
        this.totalUser = res.data.totalUsers;
      }
    })
    this.authenticationService.subscriptionPlansList().subscribe((res:any)=>{
      this.allSubscriptionPlanList=res.data
    this.authenticationService.getSubscriptionReport(this.curmonth, this.curyear).subscribe((response: any) => {
      this.plansOrder(res.data,response.data.planSubscribers);
      this.subscribers = this.eachPlanSubscribers;
      this.showSubscribers=response.data.totalPlanSubscribers;
      this.subscriptionReport.totalSubscribers = response.data.totalPlanSubscribers;
      this.subscriptionReport.totalPremiumPlanSubscribers=response.data.premiumPlanSubscribers?response.data.premiumPlanSubscribers:0;
      this.subscriptionReport.totalPlusPlanSubscribers=response.data.plusPlanSubscribers?response.data.plusPlanSubscribers:0;
      this.subscriptionReport.totalBoostPlanSubscribers=response.data.boostPlanSubscribers?response.data.boostPlanSubscribers:0;
      this.subscriptionReport.totalSuperLikePlanSubscribers=response.data.superLikePlanSubscribers?response.data.superLikePlanSubscribers:0;
      this.subscriptionReport.totalSeeWhoLikeYouPlanSubscribers=response.data.seeWhoLikeYouPlanSubscribers?response.data.seeWhoLikeYouPlanSubscribers:0;
      this.subscriptionReport.totalBoost24HrsPlanSubscribers=response.data.boost24HrsPlanSubscribers?response.data.boost24HrsPlanSubscribers:0;
      if(this.eachPlanSubscribers.length){
        this.createChart('bar');
      }
    });
  });
  }

  public filterAllPlans(value:number){
    this.eachPlanSubscribers=[];
    const month = this.subscriptionReportForm.get('month').value;
    const year = this.subscriptionReportForm.get('year').value
   this.authenticationService.getSubscriptionReport(month, year).subscribe((response: any) => {
    this.plansOrder(this.allSubscriptionPlanList,response.data.planSubscribers);
    this.subscribers = this.eachPlanSubscribers;
    this.showSubscribers=response.data.totalPlanSubscribers;
    this.subscriptionReport.totalSubscribers = response.data.totalPlanSubscribers;
    this.subscriptionReport.totalPremiumPlanSubscribers=response.data.premiumPlanSubscribers?response.data.premiumPlanSubscribers:0;
    this.subscriptionReport.totalPlusPlanSubscribers=response.data.plusPlanSubscribers?response.data.plusPlanSubscribers:0;
    this.subscriptionReport.totalBoostPlanSubscribers=response.data.boostPlanSubscribers?response.data.boostPlanSubscribers:0;
    this.subscriptionReport.totalSuperLikePlanSubscribers=response.data.superLikePlanSubscribers?response.data.superLikePlanSubscribers:0;
    this.subscriptionReport.totalSeeWhoLikeYouPlanSubscribers=response.data.seeWhoLikeYouPlanSubscribers?response.data.seeWhoLikeYouPlanSubscribers:0;
    this.subscriptionReport.totalBoost24HrsPlanSubscribers=response.data.boost24HrsPlanSubscribers?response.data.boost24HrsPlanSubscribers:0;
     if(this.eachPlanSubscribers.length){
       this.createChart(this.selectedOptionforRev);
     }
   });
  }

  onOptionSelectedForRev(value: string) {
    this.selectedOptionforRev = value;
    this.createChart(this.selectedOptionforRev);
  }

  public createChart( type: string): void {
    const lables = this.setChartLables();
   this.chart = new Chart('myChart', {
     type: type,
     data: {
       labels: lables.xAxisValue,
       datasets: [{
        label: `${lables.value} Subscribers`,
        data: lables.yAxisValue,
        backgroundColor: [
          'rgba(243, 86, 86, 0.8)', // Basic1
          'rgba(209, 150, 49, 0.8)', // Basic2
          'rgba(174, 209, 29, 0.8)', // Basic3
          'rgba(37, 198, 187, 0.8)', // Premium1
          'rgba(98, 108, 4, 0.8)', // Premium2
          'rgba(10, 128, 241, 0.8)', // Premium3
          'rgba(151, 127, 151, 0.8)', // Plus1
          'rgba(19, 17, 44, 0.8)', // Plus2
          'rgba(194, 171, 21, 0.8)', // Plus3
          'rgba(255, 98, 0, 0.8)', // 1_bbost
          'rgba(1, 34, 92, 0.8)', // boost_10
          'rgba(238, 70, 255, 0.8)', // boost_20
          'rgba(173, 71, 255, 0.8)', // superlike1
          'rgba(130, 91, 255, 0.8)', // superlike2
          'rgba(105, 140, 255, 0.8)', // superlike3
          'rgba(70, 194, 255, 0.8)', // seeWhoLikeYou1
          'rgba(38, 230, 255, 0.8)',// seeWhoLikeYou2
          'rgba(0, 255, 214, 0.8)', // seeWhoLikeYou3
          'rgba(0, 214, 126, 0.8)' // boost24Hrs
        ],
        borderColor: [
          'rgba(243, 86, 86, 0.8)', // Basic1
          'rgba(209, 150, 49, 0.8)', // Basic2
          'rgba(174, 209, 29, 0.8)', // Basic3
          'rgba(37, 198, 187, 0.8)', // Premium1
          'rgba(98, 108, 4, 0.8)', // Premium2
          'rgba(10, 128, 241, 0.8)', // Premium3
          'rgba(151, 127, 151, 0.8)', // Plus1
          'rgba(19, 17, 44, 0.8)', // Plus2
          'rgba(194, 171, 21, 0.8)', // Plus3
          'rgba(255, 98, 0, 0.8)', // 1_bbost
          'rgba(1, 34, 92, 0.8)', // boost_10
          'rgba(238, 70, 255, 0.8)', // boost_20
          'rgba(173, 71, 255, 0.8)', // superlike1
          'rgba(130, 91, 255, 0.8)', // superlike2
          'rgba(105, 140, 255, 0.8)', // superlike3
          'rgba(70, 194, 255, 0.8)', // seeWhoLikeYou1
          'rgba(38, 230, 255, 0.8)',// seeWhoLikeYou2
          'rgba(0, 255, 214, 0.8)', // seeWhoLikeYou3
          'rgba(0, 214, 126, 0.8)' // boost24Hrs
        ],
        borderWidth: 1
       }]
     },
     options: {
       scales: {
         yAxes: [{
           ticks: {
             beginAtZero: true
           }
         }]
       },
       events: [],
       plugins: {
         tooltip: {
           events: ['click']
         }
       }
     }
   });
 }

  public setChartLables(){
    const value = this.subscriptionReportForm.get('plans').value;
    const xAxisValue=[];
    const yAxisValue=[];
       this.subscribers.forEach((plans)=>{
         xAxisValue.push(plans.childPlanId);
         yAxisValue.push(plans.subscribers);
       });
    return {xAxisValue, yAxisValue, value}
  }

  getUpdatedMonthYears() {
    this.monthName = monthNames;
    for (var i = 2019; i <= this.curyear; i++) {
      this.years.push(i);
    }
  }

  public pushPlanSubscribers(planSchedule:any, planId: any, planName:any, subscribers: any){
    for(const plan of planSchedule){
      const subscribersData={
            planName: planName,
            basePlanId: planId,
            childPlanId: plan.planId,
            subscribers: subscribers[plan.planId],
            planDuration:plan.planValidity
      };
      if(planId === 'boost'){
        subscribersData.planDuration = plan.boostCount;
      }
      if(planId === 'superLikes'){
        subscribersData.planDuration = plan.superLikeCount;
      }
      this.eachPlanSubscribers.push(subscribersData);
    }
  }

  public plansOrder(subscriptionPlans:any,revenue: any){
    for(const plan of subscriptionPlans){
          this.pushPlanSubscribers(plan.planSchedule, plan.planId, plan.planName, revenue);
    }
  }
  public generateSubscriptionReportForm(){
    this.subscriptionReportForm = new FormGroup({
      month: new FormControl(this.curmonth, [Validators.required]),
      year: new FormControl(this.curyear, [Validators.required]),
      plans: new FormControl(this.dropdownPlanIds[0], [Validators.required]),
    });
  }

  public filterPlans(value: string){
    const updatedValue = value.toLocaleLowerCase();
   const filterPlans = (this.eachPlanSubscribers.filter((plans)=>{
     let planId = plans.basePlanId.toLocaleLowerCase();
     return  updatedValue.includes(planId);
   }
   ));
   if(filterPlans.length){
   this.subscribers=filterPlans;
   }
   else{
   this.subscribers= this.eachPlanSubscribers;
   }
   this.createChart(this.selectedOptionforRev);
   this.updatedSubscriptionReport(value);
 }

 public updatedSubscriptionReport(value:string){
   if(value === this.dropdownPlanIds[1]){
     this.showSubscribers=this.subscriptionReport.totalPremiumPlanSubscribers
   }
   else if(value === this.dropdownPlanIds[2]){
     this.showSubscribers=this.subscriptionReport.totalPlusPlanSubscribers;
   }
   else if(value === this.dropdownPlanIds[3]){
     this.showSubscribers=this.subscriptionReport.totalBoostPlanSubscribers;
   }
   else if(value === this.dropdownPlanIds[4]){
     this.showSubscribers=this.subscriptionReport.totalSuperLikePlanSubscribers;
   }
   else{
     this.showSubscribers=this.subscriptionReport.totalSubscribers;
   }
 }

}
