<div class="v_newloginouter fullpage-background">
  <div class="container-fluid ">
    <div class="row overlapSection overlapSectionMedia justify-content-center ">
      <div class="col-12 col-md-10 col-lg-4 col-xl-4 login-section-wrapper login-section-wrapper-media v_center">
        <div class="v_sm4">
          <div class="brand-wrapper">
            <img src="assets/images/logo_final1_black.png" alt="logo" class="logo">
          </div>
          <div class="login-wrapper my-auto">
            <h1 class="login-title">Admin Login</h1>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" name="email" class="form-control" placeholder="email@example.com"
                  formControlName="email" id="email"
                  [ngClass]="{ 'is-invalid': submitted && loginForm.controls.email.errors }">
                <div
                  *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty)"
                  class="invalid-feedback" style="display: block;">
                  <div *ngIf="loginForm.controls.email.errors.email">Enter a valid email address</div>
                  <div *ngIf="loginForm.controls.email.errors.required">Email field is required!</div>
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="password">Password</label>
                <input type="password" formControlName="password" id="password" class="form-control"
                  placeholder="Enter your password" [ngClass]="loginForm.controls.password">
                <div
                  *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty)"
                  class="invalid-feedback" style="display: block;">
                  <div *ngIf="loginForm.controls.password.errors.required">
                    Password is required.
                  </div>
                </div>
              </div>
              <input name="login" id="login" class="btn btn-block login-btn" type="submit" value="Login">
            </form>
            <!-- <a routerLink="/forgot-password" class="forgot-password-link">Forgot password?</a>
            <p class="login-wrapper-footer-text"><a routerLink="/register"
                class="text-reset">Don't have an account? Register here</a></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>