import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './components/users/users.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AuthGuard } from './guards/auth.guards';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { SubscriptionPlansComponent } from './components/subscription-plans/subscription-plans.component';
import { SubscriptionPlanDetailsComponent } from './components/subscription-plan-details/subscription-plan-details.component';
import { CreateSubscriptionPlansComponent } from './components/create-subscription-plans/create-subscription-plans.component';
import { EditSubscriptionPlansComponent } from './components/edit-subscription-plans/edit-subscription-plans.component';
import { SubscriberComponent } from './components/subscriber/subscriber.component';
import { SubscriberDetailsComponent } from './components/subscriber-details/subscriber-details.component';
import { ManageContentComponent } from './components/manage-content/manage-content.component';
import { ContentDetailsComponent } from './components/content-details/content-details.component';
import { EditContentComponent } from './components/edit-content/edit-content.component';
import { RevenueComponent } from './components/revenue/revenue.component';
import { SubscriptionReportComponent } from './components/subscription-report/subscription-report.component';
import { VerifyRealTimeImageComponent } from './components/verify-real-time-image/verify-real-time-image.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'users',  component: UsersComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'user-profile/:id', canActivate: [AuthGuard], component: UserProfileComponent },
  { path: 'change-password/:id', canActivate: [AuthGuard], component: ChangePasswordComponent },
  { path: 'user/:id', canActivate: [AuthGuard], component: EditUserComponent },
  { path: 'subscription-plans', canActivate: [AuthGuard], component: SubscriptionPlansComponent },
  { path: 'subscription-plans/:id', canActivate: [AuthGuard], component: SubscriptionPlanDetailsComponent },
  { path: 'subscription-plan', canActivate: [AuthGuard], component: CreateSubscriptionPlansComponent },
  { path: 'update-subscription/:id', canActivate: [AuthGuard], component: EditSubscriptionPlansComponent },
  { path: 'subscriber-list', canActivate: [AuthGuard], component: SubscriberComponent },
  { path: 'subscriber-details/:id', canActivate: [AuthGuard], component: SubscriberDetailsComponent },
  { path: 'manage-content', canActivate: [AuthGuard], component: ManageContentComponent },
  { path: 'edit-content/:name', canActivate: [AuthGuard], component: EditContentComponent },
  { path: 'content-details/:name', canActivate: [AuthGuard], component: ContentDetailsComponent },
  { path: 'revenue', canActivate: [AuthGuard], component: RevenueComponent },
  { path: 'subscription-report', canActivate: [AuthGuard], component: SubscriptionReportComponent },
  { path : 'verify-realtimeImage', canActivate: [AuthGuard], component: VerifyRealTimeImageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
