<div class="right_col screen-height u_scrns edit-page" role="main">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" id='mag-profile'>

            <div class="card  mb-4">
                <h3 class="m-0 font-weight-bold profile-title"><a [routerLink]="['/users']"><i
                            class="fa fa-arrow-left mr-2"></i></a>Profile</h3>
                <div class="form-group formups">
                    <div>
                        <div class="card-body">
                            <table id="tmtable" class="display " style="width:100%" class="table row-border hover"
                            width="100%" cellspacing="0" id="plans-table" *ngIf="userData">
                                <tbody>
                                    <tr>
                                        <th width="15%">Name</th>
                                        <td id="tabledeatils">{{userData.name}}</td>
                                    </tr>
                                    <!-- <tr>
                                          <th width="15%">Last Name</th>
                                          <td id="tabledeatils">{{userData.name}}</td>
                                        </tr> -->
                                    <tr *ngIf="userData.email">
                                        <th>Email</th>
                                        <td>{{userData.email}}</td>
                                    </tr>
                                    <tr *ngIf="userData.phone">
                                        <th>Contact Number</th>
                                        <td>{{userData.phone}}</td>
                                    </tr>
                                    <tr *ngIf="userData.birthDate">
                                        <th>Birthday</th>
                                        <td>{{userData.birthDate | date}}</td>
                                    </tr>
                                    <tr *ngIf="userData.sexualOrientation.length>0">
                                        <th>Sexual Orientation</th>
                                        <td>{{userData.sexualOrientation}}</td>
                                    </tr>
                                    <tr *ngIf="userData.sexualInterest">
                                        <th>Sexual Interest</th>
                                        <td>{{userData.sexualInterest}}</td>
                                    </tr>
                                    <tr *ngIf="userData.interest.length>0">
                                        <th>Interest</th>
                                        <td>{{userData.interest}}</td>
                                    </tr>
                                    <tr *ngIf="userData.photoUrl">
                                        <th>Photo URL</th>
                                        <td>{{userData.photoUrl}}</td>
                                    </tr>
                                    <tr *ngIf="userData.age">
                                        <th>Age</th>
                                        <td>{{userData.age}}</td>
                                    </tr>
                                    <tr *ngIf="userData.maxDistance?.distance">
                                        <th>Max Distance</th>
                                        <td>{{userData.maxDistance.distance}}
                                            ({{userData.maxDistance.showInRange ?
                                            'Show in Range' : 'Do Not Show in Range'}})</td>
                                    </tr>
                                    <tr *ngIf="userData.ageRange && userData.ageRange.minAge && userData.ageRange.maxAge">
                                        <th>Age Range</th>
                                        <td>{{userData.ageRange.minAge}} -
                                            {{userData.ageRange.maxAge}}
                                            ({{userData.ageRange.showInRange ? 'Show in Range' : 'Do Not Show inRange'}})</td>
                                    </tr>
                                    <tr *ngIf="userData.blocked.length>0">
                                        <th>Blocked Users</th>
                                        <td>
                                            <textarea [value]="userData.blocked.join('\n')" readonly></textarea>
                                        </td>
                                    </tr>
                                    <tr *ngIf="userData.likedUsers.length>0">
                                        <th>Liked Users</th>
                                        <td>
                                            <textarea [value]="userData.likedUsers.join('\n')" readonly></textarea>
                                        </td>
                                    </tr>
                                    <tr *ngIf="userData.likedBy.length>0">
                                        <th>Liked By Users</th>
                                        <td>
                                            <textarea [value]="userData.likedBy.join('\n')" readonly></textarea>
                                        </td>
                                    </tr>
                                    <tr *ngIf="userData.university">
                                        <th>University</th>
                                        <td>{{userData.university}}</td>
                                    </tr>
                                    <tr *ngIf="userData.aboutMe">
                                        <th>About Me</th>
                                        <td>{{userData.aboutMe}}</td>
                                    </tr>
                                    <tr *ngIf="userData.gender">
                                        <th>Gender</th>
                                        <td>{{userData.gender}}</td>
                                    </tr>
                                    <tr *ngIf="userData.zodiac">
                                        <th>Zodiac</th>
                                        <td>{{userData.zodiac}}</td>
                                    </tr>
                                    <tr *ngIf="userData.lifestyle.drinking">
                                        <th>Drinking</th>
                                        <td>{{userData.lifestyle.drinking}}</td>
                                    </tr>
                                    <tr *ngIf="userData.lifestyle.smoking">
                                        <th>Smoking</th>
                                        <td>{{userData.lifestyle.smoking}}</td>
                                    </tr>
                                    <tr *ngIf="userData.lifestyle.workout">
                                        <th>Workout</th>
                                        <td>{{userData.lifestyle.workout}}</td>
                                    </tr>
                                    <tr *ngIf="userData.lifestyle.sleepingHabbits">
                                        <th>Sleeping Habits</th>
                                        <td>{{userData.lifestyle.sleepingHabbits}}</td>
                                    </tr>
                                    <tr *ngIf="userData.lifestyle.socialMedia">
                                        <th>Social Media</th>
                                        <td>{{userData.lifestyle.socialMedia}}</td>
                                    </tr>
                                    <tr *ngIf="userData.isSubscriber">
                                        <th>Subscription Plan</th>
                                        <td>{{userData.isSubscriber.planName}}</td>
                                    </tr>
                                    <tr *ngIf="userData.isSubscriber">
                                        <th>Subscription Plan Starts from</th>
                                        <td>{{userData.isSubscriber.planStartDate | date}}</td>
                                    </tr>
                                    <tr *ngIf="userData.isSubscriber">
                                        <th>Subscription Plan Expires On</th>
                                        <td>{{userData.isSubscriber.planExpiryDate | date}}</td>
                                    </tr>
                                    <tr>
                                        <th> Account Created Date</th>
                                        <td>{{userData.createdDate | date}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>

