<div class="right_col screen-height u_scrns edit-page" role="main">
    <div class="col-md-12 amenity-size login-wrapper col-sm-12 col-xs-12" id='mag-profile'>
        <div class="cardt  mb-4">
            <h3 class="font-weight-bold profile-title mbb"><a [routerLink]="['/subscription-plans']"><i
                        class="fa fa-arrow-left mr-2"></i></a>Subscription Plans Details</h3>
            <div class="row">
                <div class="col-sm-12">
                    <div>
                        <table id="tmtable" class="display " style="width:100%" class="table row-border hover"
                            width="100%" cellspacing="0" id="plans-table" *ngIf="subscriptionData">
                            <tbody>
                                <tr>
                                    <th>Plan Name</th>
                                    <td id="tabledeatils">{{subscriptionData.planName}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <th>Plan Id</th>
                                    <td>{{subscriptionData.planId}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <th>Active</th>
                                    <td>{{subscriptionData.active}}</td>
                                </tr>
                            </tbody>
                            <!-- <tbody>
                                <tr>
                                    <th>Likes</th>
                                    <td>{{subscriptionData.like}}</td>
                                </tr>
                            </tbody> -->
                            <tbody>
                                <tr>
                                    <th>Plan Validity</th>
                                    <th>Plan Price</th>
                                    <th>Discount</th>
                                    <th>Per Month Plan-Price</th>
                                </tr>
                                <tr *ngFor="let plans of subscriptionData.planSchedule">
                                    <td>{{plans.planValidity}} Months</td>
                                    <td>$ {{plans.planPrice}}</td>
                                    <td>{{plans.discount}}%</td>
                                    <td>Rs.{{plans.perMonthPlanPrice}}</td>
                                </tr>
                            </tbody>
                            <!-- <tbody>
                                <tr>
                                    <th>Plan Validity</th>
                                    <td>{{subscriptionData.planValidity}}</td>
                                </tr>
                            </tbody> -->
                            
                            <!-- <tbody>
                                <tr>
                                    <th>Perks</th>
                                    <td *ngIf="subscriptionData && subscriptionData.perks.length === 0; else perksElse">
                                        Not
                                        Available</td>
                                    <ng-template #perksElse>
                                        <li *ngFor="let item of subscriptionData.perks; index as i; trackBy: trackByFn">
                                            {{item}}</li>
                                    </ng-template>
                                </tr>
                            </tbody> -->
                           
                          
                        </table>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <div class="title_left">
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

    </div>
</div>