import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import * as Chart from 'chart.js';
import { AVAILABLE_PLANS } from '../../constants/code-constants'
import { monthNames } from '../../constants/code-constants'
import { REVENUE } from 'src/app/interfaces/interface';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})

export class RevenueComponent implements OnInit {
  public revenueReport = {
    totalPlansRevenue: 0,
    totalSubscriptions: 0,
    totalPremiumPlanRevenue:0,
    totalPlusPlanRevenue:0,
    totalBoostPlanRevenue:0,
    totalSuperLikePlanRevenue:0,
    totalSeeWhoLikeYouPlanRevenue:0,
    totalboost24HrsPlanRevenue:0
  }
  public chart: any;
  public currentDate = new Date();
  public curmonth: string = (this.currentDate.getMonth() + 1).toString();
  public curyear: any = (this.currentDate.getFullYear()).toString();
  public monthName: any = [];
  public years: any = [];
  public selectedOptionforRev: string='bar';
  public showRevenue:number;
  public premiumPlans:Array<any>=[];
  public plusPlans:Array<any>=[];
  public boostPlans:Array<any>=[];
  public superLikePlans:Array<any>=[];
  public seeWhoLikeYouPlans:Array<any>=[];
  public boost24HrsPlans:Array<any>=[];
  public dropdownPlanIds:Array<string>=AVAILABLE_PLANS;
  public subscriptionPlansWithRevenue: Array<REVENUE>=[];
  public subscriptionPlans: Array<REVENUE>=[];
  public revenueReportForm: FormGroup;
  public allSubscriptionPlanList:Array<Object>;

  constructor(private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    let newDate = new Date();
    this.curmonth = (newDate.getMonth() + 1).toString();
  }
  ngOnInit(): void {
    this.getUpdatedMonthYears();
    this.generateRevenueForm();
    this.authenticationService.subscriptionPlansList().subscribe((res:any)=>{

      this.allSubscriptionPlanList=res.data;
      this.authenticationService.getRevenueReport(this.curmonth, this.curyear).subscribe((response: any) => {
        this.plansOrder(res.data,response.data.planRevenue);
        this.subscriptionPlans = this.subscriptionPlansWithRevenue;
        this.revenueReport.totalPlansRevenue= response.data.totalRevenue;
        this.showRevenue=response.data.totalRevenue;
        this.revenueReport.totalSubscriptions = this.subscriptionPlansWithRevenue.length;
        this.revenueReport.totalPremiumPlanRevenue=response.data.premiumPlanRevenue?response.data.premiumPlanRevenue:0;
        this.revenueReport.totalPlusPlanRevenue=response.data.plusPlanRevenue?response.data.plusPlanRevenue:0;
        this.revenueReport.totalBoostPlanRevenue=response.data.boostPlanRevenue?response.data.boostPlanRevenue:0;
        this.revenueReport.totalSuperLikePlanRevenue=response.data.superLikePlanRevenue?response.data.superLikePlanRevenue:0;
        this.revenueReport.totalSeeWhoLikeYouPlanRevenue=response.data.seeWhoLikeYouPlanRevenue?response.data.seeWhoLikeYouPlanRevenue:0;
        this.revenueReport.totalboost24HrsPlanRevenue=response.data.boost24HrsPlanRevenue?response.data.boost24HrsPlanRevenue:0;
        if(this.subscriptionPlansWithRevenue.length){
          this.createChart('bar');
        }
      })
    });

  }
   public filterAllPlans(value:number){
     this.subscriptionPlansWithRevenue=[];
     const month = this.revenueReportForm.get('month').value;
     const year = this.revenueReportForm.get('year').value
    this.authenticationService.getRevenueReport(month, year).subscribe((response: any) => {
      this.plansOrder(this.allSubscriptionPlanList,response.data.planRevenue);
      this.subscriptionPlans = this.subscriptionPlansWithRevenue;
      this.revenueReport.totalPlansRevenue= response.data.totalRevenue;
      this.showRevenue=response.data.totalRevenue;
      this.revenueReport.totalSubscriptions = this.subscriptionPlansWithRevenue.length;
      this.revenueReport.totalPremiumPlanRevenue=response.data.premiumPlanRevenue?response.data.premiumPlanRevenue:0;
      this.revenueReport.totalPlusPlanRevenue=response.data.plusPlanRevenue?response.data.plusPlanRevenue:0;
      this.revenueReport.totalBoostPlanRevenue=response.data.boostPlanRevenue?response.data.boostPlanRevenue:0;
      this.revenueReport.totalSuperLikePlanRevenue=response.data.superLikePlanRevenue?response.data.superLikePlanRevenue:0;
      this.revenueReport.totalSeeWhoLikeYouPlanRevenue=response.data.seeWhoLikeYouPlanRevenue?response.data.seeWhoLikeYouPlanRevenue:0;
      this.revenueReport.totalboost24HrsPlanRevenue=response.data.boost24HrsPlanRevenue?response.data.boost24HrsPlanRevenue:0;
      if(this.subscriptionPlansWithRevenue.length){
        this.createChart('bar');
      }
    });
   }

  public createChart( type: string): void {
     const lables = this.setChartLables();
    this.chart = new Chart('myChart', {
      type: type,
      data: {
        labels: lables.xAxisValue,
        datasets: [{
          label: `Revenue from ${lables.value} Subscriptions`,
          data: lables.yAxisValue,
          backgroundColor: [
            'rgba(243, 86, 86, 0.8)', // Basic1
            'rgba(209, 150, 49, 0.8)', // Basic2
            'rgba(174, 209, 29, 0.8)', // Basic3
            'rgba(37, 198, 187, 0.8)', // Premium1
            'rgba(98, 108, 4, 0.8)', // Premium2
            'rgba(10, 128, 241, 0.8)', // Premium3
            'rgba(151, 127, 151, 0.8)', // Plus1
            'rgba(19, 17, 44, 0.8)', // Plus2
            'rgba(194, 171, 21, 0.8)', // Plus3
            'rgba(255, 98, 0, 0.8)', // 1_bbost
            'rgba(1, 34, 92, 0.8)', // boost_10
            'rgba(238, 70, 255, 0.8)', // boost_20
            'rgba(173, 71, 255, 0.8)', // superlike1
            'rgba(130, 91, 255, 0.8)', // superlike2
            'rgba(105, 140, 255, 0.8)', // superlike3
            'rgba(70, 194, 255, 0.8)', // seeWhoLikeYou1
            'rgba(38, 230, 255, 0.8)',// seeWhoLikeYou2
            'rgba(0, 255, 214, 0.8)', // seeWhoLikeYou3
            'rgba(0, 214, 126, 0.8)' // boost24Hrs
          ],
          borderColor: [
            'rgba(243, 86, 86, 0.8)', // Basic1
            'rgba(209, 150, 49, 0.8)', // Basic2
            'rgba(174, 209, 29, 0.8)', // Basic3
            'rgba(37, 198, 187, 0.8)', // Premium1
            'rgba(98, 108, 4, 0.8)', // Premium2
            'rgba(10, 128, 241, 0.8)', // Premium3
            'rgba(151, 127, 151, 0.8)', // Plus1
            'rgba(19, 17, 44, 0.8)', // Plus2
            'rgba(194, 171, 21, 0.8)', // Plus3
            'rgba(255, 98, 0, 0.8)', // 1_bbost
            'rgba(1, 34, 92, 0.8)', // boost_10
            'rgba(238, 70, 255, 0.8)', // boost_20
            'rgba(173, 71, 255, 0.8)', // superlike1
            'rgba(130, 91, 255, 0.8)', // superlike2
            'rgba(105, 140, 255, 0.8)', // superlike3
            'rgba(70, 194, 255, 0.8)', // seeWhoLikeYou1
            'rgba(38, 230, 255, 0.8)',// seeWhoLikeYou2
            'rgba(0, 255, 214, 0.8)', // seeWhoLikeYou3
            'rgba(0, 214, 126, 0.8)' // boost24Hrs
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        events: [],
        plugins: {
          tooltip: {
            events: ['click']
          }
        }
      }
    });
  }

  public setChartLables(){
    const value = this.revenueReportForm.get('plans').value;
    const xAxisValue=[];
    const yAxisValue=[];
       this.subscriptionPlans.forEach((plans)=>{
         xAxisValue.push(plans.childPlanId);
         yAxisValue.push(plans.revenue);
       });
    return {xAxisValue, yAxisValue, value}
  }

  public onOptionSelectedForRev(value: string) {
    this.selectedOptionforRev = value;
    this.createChart(this.selectedOptionforRev);
  }

  public getUpdatedMonthYears() {
    this.monthName = monthNames;
    for (var i = 2019; i <= this.curyear; i++) {
      this.years.push(i);
    }
  }

  public pushPlanRevenue(planSchedule:any, planId: any, planName:any, revenue: any){
    for(const plan of planSchedule){
      const revenueData={
            planName: planName,
            basePlanId: planId,
            childPlanId: plan.planId,
            revenue: revenue[plan.planId] !== undefined?revenue[plan.planId]:0,
            planDuration:plan.planValidity
      };
      if(planId === 'boost'){
        revenueData.planDuration = plan.boostCount;
      }
      if(planId === 'superLikes'){
        revenueData.planDuration = plan.superLikeCount;
      }
      this.subscriptionPlansWithRevenue.push(revenueData);
    }
  }

  public plansOrder(subscriptionPlans:any,revenue: any){
    for(const plan of subscriptionPlans){
          this.pushPlanRevenue(plan.planSchedule, plan.planId, plan.planName, revenue);
    }
  }
  public generateRevenueForm(){
    this.revenueReportForm = new FormGroup({
      month: new FormControl(this.curmonth, [Validators.required]),
      year: new FormControl(this.curyear, [Validators.required]),
      plans: new FormControl(this.dropdownPlanIds[0], [Validators.required]),
    });
  }

  public filterPlans(value: string){
     const updatedValue = value.toLocaleLowerCase();
    const filterPlans = (this.subscriptionPlansWithRevenue.filter((plans)=>{
      let planId = plans.basePlanId.toLocaleLowerCase();
      return  updatedValue.includes(planId);
    }
    ));
    if(filterPlans.length){
    this.subscriptionPlans=filterPlans;
    }
    else{
    this.subscriptionPlans= this.subscriptionPlansWithRevenue;
    }
    this.createChart(this.selectedOptionforRev);
    this.updatedShowRevenue(value);
  }

  public updatedShowRevenue(value:string){
    if(value === this.dropdownPlanIds[1]){
      this.showRevenue=this.revenueReport.totalPremiumPlanRevenue
    }
    else if(value === this.dropdownPlanIds[2]){
      this.showRevenue=this.revenueReport.totalPlusPlanRevenue;
    }
    else if(value === this.dropdownPlanIds[3]){
      this.showRevenue=this.revenueReport.totalBoostPlanRevenue;
    }
    else if(value === this.dropdownPlanIds[4]){
      this.showRevenue=this.revenueReport.totalSuperLikePlanRevenue;
    }
    else{
      this.showRevenue=this.revenueReport.totalPlansRevenue;
    }
  }
}
