import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { AuthenticationService } from './../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

// This component is to change password
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  passwordValid = true;
  submitted = false;
  id
  constructor(private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
    this.createForm();
  }

  // Function to call when page loads
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      oldPassword: ['', Validators.compose([Validators.required])],
      newPassword: ['', Validators.compose([Validators.required])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    });
  }

  // This function is called when we submit the form with new password value.
  onSubmit(): void {
    this.submitted = true;
    const formVal = this.resetPasswordForm.value;
    const data = {
      oldPassword: (formVal && formVal.oldPassword) ? formVal.oldPassword : JSON.stringify(this.toastr.error('Old password is required!')),
      newPassword: (formVal && formVal.newPassword) ? formVal.newPassword : JSON.stringify(this.toastr.error('New password is required!')),
      confirmPassword: (formVal && formVal.confirmPassword) ? formVal.confirmPassword : JSON.stringify(this.toastr.error('Confirm password is required!')),
    };
    // Here we check if value of confirm password is smae or not
    if (this.passwordValid === false) {
      this.toastr.error('Confirm password must be same as password!');
    } else {
      // Here pass the value to function to update new password
      this.authenticationService.resetPassword(this.id, data).subscribe(
        (res: any) => {
          if (res.body.statusCode == 200) {
            this.toastr.success(res.body.message);
            this.router.navigateByUrl('dashboard');
          }
          // localStorage.removeItem('admin');
          // localStorage.removeItem('user');
        }, error => {
          this.toastr.error(error.error.message, 'Failure!', { progressBar: true });
        });
    }
  }
  // Here we check if old password is correct or not
  checkPasswords(pass, confirmPass) { // here we have the 'passwords' group
    if (pass === confirmPass) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
    }

  }

}