import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CodeConstants } from 'src/app/constants/code-constants';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss']
})
export class ContentDetailsComponent implements OnInit {
  id: any;
  loggedInAdmin: any;
  contentData: any;
  options: any=[];
  showDialog: number=0;
  deleteOptionVal: any;
  display = 'none';
  addModalDisplay ='none';
  editModalDisplay ='none';
  deleteOptionIndex: number;
  contentName:string='';
  selectedOption:any;
  confirmOptionName: string = '';
  confirmOptionId: number = 0;
  showAddModal: boolean = false;
  showDeleteModal: boolean = false;
  showEditModal: boolean = false;
  codeConstants=CodeConstants;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.contentName = this.route.snapshot.paramMap.get('name');
    this.loggedInAdmin = JSON.parse(localStorage.getItem('admin'));

    this.fetchcontentDataById();
  }

  fetchcontentDataById() {
    if (this.contentName === this.codeConstants.HOBBIES) {
      this.authenticationService.getHobbies().subscribe((res: any) => {
        this.contentData = res
        this.options=res.hobbies
        .map(({name, hobbiesId}) => ({name, id:hobbiesId}));
      })
    }
    else if(this.contentName === this.codeConstants.ORIENTATIONS){
      this.authenticationService.getOrientations().subscribe((res: any) => {
        this.contentData = res
        this.options=res.sexOrientation
        .map(({name, orientationId}) => ({name, id:orientationId}));
      })
    }
    else if(this.contentName === this.codeConstants.EDUCATIONS){
      this.authenticationService.getEducations().subscribe((res: any) => {
        this.contentData = res
        this.options=res.education
        .map(({name, educationId}) => ({name, id:educationId}));
      })
    }
    else if(this.contentName === this.codeConstants.RELIGIONS){
      this.authenticationService.getReligions().subscribe((res: any) => {
        this.contentData = res
        this.options=res.religion
        .map(({name, religionId}) => ({name, id:religionId}));
      })
    }
     else {
      this.toastr.error("Error while fetching the requested content");
    }
  }
}
