import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-subscriber-details',
  templateUrl: './subscriber-details.component.html',
  styleUrls: ['./subscriber-details.component.scss']
})
export class SubscriberDetailsComponent implements OnInit {
  public subscriber;
  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    this.getUser(id)
  }
  getUser(id) {
    this.authenticationService.getDetailsOfSubscribedUser(id).subscribe(res => {
      this.subscriber = res.data;
    })
  }

}
