import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

// This component is for login page
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private storageService: StorageService,
    private router: Router) {
    this.createForm();
  }

  ngOnInit(): void {
    const loggedInAdmin = JSON.parse(localStorage.getItem('admin'));
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    if (loggedInAdmin && loggedInAdmin.token) {
      this.router.navigate(['/dashboard']);
    } else if (loggedInUser && loggedInUser.token) {
      this.router.navigate(['/dashboard']);
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // Here we validate the login credentials
  onSubmit(): void {
    this.submitted = true;
    const formVal = this.loginForm.value;
    const data = {
      email: (formVal && formVal.email) ? formVal.email : JSON.stringify(this.toastr.error('Email is required!')),
      password: (formVal && formVal.password) ? formVal.password : JSON.stringify(this.toastr.error('Password is required!')),
    };
    this.authenticationService.loginApi(data).subscribe(
      res => {
        if (res && res.statusCode == 200) {
          this.storageService.setItemInLocalStorage('admin', JSON.stringify({
            token: res.accessToken.access_token,
            email: res.userEmail,
          }));
          this.toastr.success(res.message, 'Login successfully!', { progressBar: true });
          this.router.navigate(['/dashboard']);
        } else {
          this.toastr.error(res.message, 'Unable to Login', { progressBar: true });
        }
      }, err => {
        if (err.status === 400) {
          this.toastr.error(err.error.message, 'Failure!', { progressBar: true });
        } else if (err.status === 404) {
          this.toastr.error(err.error.message, 'Failure!', { progressBar: true });
        } else if (err.status === 401) {
          this.toastr.error(err.error.message, 'Invalid Credentials', { progressBar: true });
        } else{
          this.toastr.error(err.error.message, err.error.message, { progressBar: true });
        }
      });
  }
}
