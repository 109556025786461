<div class="right_col screen-height v_scrnss v_cm edit-page" role="main">
    <div class="col-md-12 amenity-size login-wrapper">
        <div class="text-left">
            <h3 class="mb-4"><a routerLink="/manage-content"><i class="fa fa-arrow-left mr-2"></i></a>Edit {{contentName}}</h3>
        </div>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="th-heading">ID</th>
                        <th class="th-heading">Name</th>
                        <th class="th-heading">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let option of options">
                        <td>{{ option.id + 1 }}</td>
                        <td>{{ option.name }}</td>
                        <td>
                            <button type="button" class="btn btn-outline-info user-glyf" (click)="openEditModal(option)">Edit</button>
                            <button type="button" class="btn btn-outline-danger user-glyf" (click)="confirmDeleteOption(option.id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <form class="form-inline mt-3">
            <div class="form-group mr-2">
                <label for="option-name" class="sr-only">Enter name</label>
                <input type="text" id="option-name" class="form-control" placeholder="Enter name" required #name>
            </div>
            <button type="submit" class="btn btn-block login-btn" (click)="confirmAddOption(name.value); name.value=''">Add</button>
        </form>
    </div>

    <!-- Add Confirmation Modal -->
    <div [ngStyle]="showAddModal? {'display':'block'} : {'display':'none'}" class="modal" id="confirmAddModal" tabindex="-1" role="dialog" aria-labelledby="confirmAddModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmAddModalLabel">Confirm Addition</h5>
              <button type="button" class="close" (click)="closeAddModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to add this in {{contentName}} ?
              <br>
              <b>{{confirmOptionName}}</b>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="addContentOption(confirmOptionName)" data-dismiss="modal">Add</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Delete Confirmation Modal -->
      <div [ngStyle]="showDeleteModal? {'display':'block'} : {'display':'none'}" class="modal" id="confirmDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmDeleteModalLabel">Confirm Deletion</h5>
              <button type="button" class="close" (click)="closeDeleteModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete this ?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="closeDeleteModal()">Cancel</button>
              <button type="button" class="btn btn-danger" (click)="deleteContentOption(confirmOptionId)" data-dismiss="modal">Delete</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Confirmation Modal -->
      <div *ngIf="showEditModal" [ngStyle]="showEditModal? {'display':'block'} : {'display':'none'}" class="modal" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editModalLabel">Edit Name</h5>
                    <button type="button" class="close" (click)="closeEditModal()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <div class="form-group">
                            <label for="editName">Name</label>
                            <input type="text" class="form-control" id="editName" [value]="selectedOption.name" #editName required>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeEditModal()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="editContentOption(editName.value)">Save changes</button>
                </div>
            </div>
        </div>
    </div>
  
</div>
