<div class="right_col screen-height v_scrnss edit-page" role="main" style="background-color: #f7f7f7;">
  <div class="container">
    <div class="ml-2 mb-4 ">
      <h1 class="text-left font-weight-bold text-uppercase text-dark">Manage Content</h1>
    </div>
    <div class="d-flex flex-column" *ngFor="let option of rows">
      <div class="col-lg-12 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h3 class="card-title">{{option.name}}</h3>
            <p class="card-text">{{option.length}} Total Values</p>
          </div>
          <div class="card-footer">
            <div class="btn-group">
              <button class="btn btn-outline-success btn-lg" [routerLink] ="['/edit-content/'+ option.name]">
                <i class="fa fa-edit"></i> Edit
              </button>
              <button class="btn btn-outline-info btn-lg" [routerLink] ="['/content-details/'+ option.name]">
                <i class="fa fa-info-circle"></i> Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>